<template>
  <div class="card">
    <div class="card-header">
      <h4 style="padding: 10px">Buy ShortCode Extension</h4>
    </div>
    <div class="card-body">
      <div class="row">
        <div class="col-md-2 col-sm-12"></div>
        <div class="col-md-9 col-sm-12">
          <form class="forms-sample" >
            <div class="form-group row" v-if="errorMsg.length">
                <div class="col-12">
                  <div class="alert alert-danger alert-dismissible fade show" role="alert">
                    <strong v-for="err in errorMsg">{{ err }}</strong>
                    <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                </div>
            </div>
            <div class="form-group row">
              <label class="col-form-label">Select Country</label>
              <div class="input-group">
                <select class="form-control"  style="
                    background-color: #2b2e4c;
                    color: #e9e6e6;
                    border: 1px solid #424351;
                  " v-model="purchase.country" @change="onCountrySelectChange()">
                  <option value="">..Select Country..</option>
                  <option value="Ghana">Ghana</option>
                  <option value="Nigeria">Nigeria</option>
                  <option value="Eswatini">Eswatini</option>
                  <option value="Kenya">Kenya</option>
                </select>
              </div>
            </div>
            <div class="form-group row" v-if="availableExtensions.length">
              <h4>Choose a starting shortcode</h4>
              <div class="table-responsive">
                <table id="order-listing" class="table">
                  <thead>
                  <tr>
                    <th>Start Code</th>
                    <th>Extension</th>
                    <th>Example</th>
                    <th>Pricing</th>
                    <th></th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="data in availableExtensions" :key="data.uuid">
                    <td>
                      <span v-show="data.selected">
                         <i class="fa fa-solid fa-check" style="color:#54f542;font-size:1.5em" ></i>
                      </span>
                      {{data.shortCode}}</td>
                    <td>{{data.extension}}</td>
                    <td>{{data.shortCode}}{{data.extension}}# </td>
                    <td>{{data.price}} {{data.currency}}/month</td>
                    <td>
                      <button type="button" class="btn btn-outline-success  ml-2 design" :disabled="data.selected" @click="selectExtension(data)" title="Select ShortCode">
                        <i class="fa fa-external-link" tooltip="Select ShortCode" ></i> Select
                      </button>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="form-group" v-if="availableExtensions.length==0">
              <div class="card card-inverse-info">
                <div class="card-body">
                  <div class="card-title font-weight-bold">No ShortCodes</div>
                  <div class="card-text">
                    <div class="row">
                      <div class="col-1">
                        <div class="badge badge-pill badge-outline-info">
                          <i class="fas fa-info-circle"></i>
                        </div>
                      </div>
                      <div class="col-11">
                            <span class="small">
                              There are not shortcodes available for selected country
                            </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="form-group" v-show="purchase.chooseExtension">
              <label>Choose a {{purchase.extLength}} digits extension</label>
              <input type="text" class="form-control"  placeholder="" v-model="purchase.extension">
            </div>
            <div class="form-group">
              <label>Purchase for </label>
              <div class="input-group">
                <select class="form-control"  style="
                    background-color: #2b2e4c;
                    color: #e9e6e6;
                    border: 1px solid #424351;
                  " v-model="purchase.period">
                  <option value="">..Select Period..</option>
                  <option value="1">1 Month</option>
                  <option value="2">2 Months</option>
                  <option value="3">3 Months</option>
                  <option value="4">4 Months</option>
                  <option value="5">5 Months</option>
                  <option value="6">6 Months</option>
                  <option value="7">7 Months</option>
                  <option value="8">8 Months</option>
                  <option value="9">9 Months</option>
                  <option value="10">10 Months</option>
                  <option value="11">11 Months</option>
                  <option value="12">12 Months</option>
                </select>
              </div>
            </div>
            <button type="button" :disabled="availableExtensions.length==0" class="btn btn-primary  mt-0 text-white float-right" @click="confirmPurchase()">
              Checkout
            </button>
          </form>
        </div>
        <div class="col-md-1 col-sm-12"></div>
      </div>
    </div>
    <div>
      <div class="modal fade" id="confirmShortCodePurchaseModal" tabindex="-1" role="dialog" aria-labelledby="ModalLabel"
           aria-hidden="true">
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="ModalLabel">
                Confirm Purchase
              </h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <form>
                <div class="form-group row">
                  <label class="col-5">Short Code :</label>
                  <span class="col-7" >
                    {{purchase.shortCode}}
                  </span>
                </div>
                <div class="form-group row">
                  <label class="col-5 ">Country :</label>
                  <span class="col-7" >
                    {{purchase.country}}
                  </span>
                </div>
                <div class="form-group row">
                  <label class="col-5">Duration of purchase :</label>
                  <span class="col-7" >
                    {{purchase.period}} months
                  </span>
                </div>
              </form>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-success text-white" @click="proceedToPayment()">
                Proceed To Payment
              </button>
              <button type="button" class="btn btn-light" @click="closeModal()">
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="modal fade" id="prodgressDialog" tabindex="-1" role="dialog" aria-labelledby="ModalLabel"
           aria-hidden="true">
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="loader-demo-box1">
              <div class="dot-opacity-loader">
                <span></span>
                <span></span>
                <span></span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import api from "@/shared/menuApi";
import { useToast } from "vue-toastification";

function openInNewTab(url) {
  window.open(url, '_blank', 'noreferrer');
}
export default {
  name: "BuyShortCode",
  components: {

  },
  setup() {
     const toast = useToast();
    return { toast };
  },
  data() {
    return {
      purchase: {
        period:"",
        country:""
      },
      errorMsg: [],
      availableExtensions:[],
    };
  },
  methods: {
     proceedToPayment(){
       jQuery("#confirmShortCodePurchaseModal").modal("hide");
       let payload = {
         leaseCount : this.purchase.period,
         extensionUuid:this.purchase.extensionUuid,
         shortCode : this.purchase.shortCode
       }
       jQuery("#prodgressDialog").modal("show");
       api.doPost("/api/subscription/buyshortcode", payload)
               .then((response) => {
                 console.log('Response from server >>', response);
                 if (response.status == "01") {
                   setTimeout(function () {
                     jQuery("#prodgressDialog").modal("hide");
                   }, 3000);
                   openInNewTab(response.redirectUrl);
                 } else {
                   jQuery("#prodgressDialog").modal("hide");
                   this.toast.info(response.message)
                 }
               })
               .catch((error) => {
                 this.toast.error(error.message);
               });
     },
     confirmPurchase(){
       if(this.validateFields()){
         jQuery("#confirmShortCodePurchaseModal").modal("show");
         if(this.purchase.chooseExtension){
           this.purchase.shortCode=`${this.purchase.shortCode}${this.purchase.extension}#`
         }
       }else{
         window.scrollTo({ top: 0, behavior: 'smooth' });
       }
     },
    onCountrySelectChange(){
      let country = this.purchase.country;
       this.getAvailableShortCodes(this.purchase.country);
      this.purchase={country:country,period: ""};
    },
    selectExtension(data){
      console.log('The data is>>',data)
      this.purchase.extensionUuid=data.uuid;
      this.purchase.shortCode=data.shortCode;
      this.purchase.extLength = data.extLength;
      if(isNaN(data.extension)){
        this.purchase.chooseExtension =true;
      }else{
        this.purchase.shortCode=`${data.shortCode}${data.extension}#`;
        this.purchase.chooseExtension =false;
      }
      for(let k=0,len=this.availableExtensions.length;k<len;k++){
        this.availableExtensions[k].selected = false;
        if(this.availableExtensions[k].uuid===data.uuid){
          console.log('Selected found >>>')
          this.availableExtensions[k].selected = true;
        }else{
          this.availableExtensions[k].selected = false;
        }
      }
    },
    closeModal(){
      jQuery("#confirmShortCodePurchaseModal").modal("hide");
    },
    getAvailableShortCodes(country) {
      let vm = this;
      api.doGet(`/api/getAvailableShortCodes/${country}`)
              .then((response) => {
                 vm.availableExtensions=response;
              })
              .catch((error) => {
                this.toast.error(error.message);
              })
    },
    validateFields(){
      this.errorMsg=[];
       if(this.purchase.extensionUuid==null){
         this.errorMsg.push("Select a shortcode extension");
         return false;
       }
       if(this.purchase.chooseExtension){
         if(isNaN(this.purchase.extension)){
            this.errorMsg.push("Enter a numeric extension");
         }
         if(this.purchase.extension!=null){
           if(this.purchase.extension.length!==this.purchase.extLength){
             this.errorMsg.push(`Extension length must be ${this.purchase.extLength} digits`);
           }
         }
       }
       return this.errorMsg.length === 0
    }
  },
};
</script>
<style scoped>
#submitBtn {
  margin-top: 20px;
}

#error {
  margin-top: 40px;
}
</style>
