<template>
  <div class="container">
    <div class="row">
      <div class="col-md-12 grid-margin">
        <div class="row">
          <div class="col-8 mb-0 mb-xl-0">
            <h4 class="font-weight-bold">Account Billing, Plans and Pricing</h4>
          </div>
        </div>
      </div>
    </div>
    <div class="card">
      <div class="card-body">
        <ul class="nav nav-pills nav-pills-success" id="pills-tab" role="tablist">
          <li class="nav-item">
            <a class="nav-link active" id="pills-plan-tab" data-bs-toggle="pill" href="#pills-plan" role="tab"
              aria-controls="pills-plan" aria-selected="true">Plans</a>
          </li>
          <li class="nav-item">
            <a class="nav-link " id="pills-history-tab" data-bs-toggle="pill" href="#pills-history" role="tab"
              aria-controls="pills-history" aria-selected="false">Payment History</a>
          </li>
        </ul>
        <div class="tab-content" id="pills-tabContent">
          <div class="tab-pane fade active show" id="pills-plan" role="tabpanel" aria-labelledby="pills-plan-tab">
            <div class="media">
              <div class="container pt-2">
                <div class="col-12 col-md-12" v-if="subscription"><!-- Card -->
                  <div class="blockquote blockquote-info">
                    <div class="row">
                      <div class="col">
                        <h4 class="mb-2">Current Plan : <span class="strong font-weight-bold">{{ subscription.plan }}
                            PLAN
                          </span>
                          <div type="button" class="btn btn-rounded btn-inverse-success btn-fw"
                            v-show="subscription.subscriptionStatus == 'active'">Active</div>
                          <div type="button" class="btn btn-rounded btn-inverse-danger btn-fw"
                            v-show="subscription.subscriptionStatus == 'inactive'">Deactivated</div>
                        </h4>
                        <div class="row" v-if="subscription.subscriptionStatus == 'inactive'">
                          <div class="alert alert-danger">
                            Your account has been disabled for none payment. Kindly make payment to activate your
                            account
                            immediately.
                          </div>
                        </div>
                      </div>
                      <div class="col-auto"><!-- Icon -->
                        <button type="button" @click="openPaymentConfirmation('BASIC', 'retry')"
                          v-if="subscription.subscriptionStatus == 'inactive'"
                          class="btn btn-md btn-success text-white font-weight-bold">Make Payment</button>
                        <button type="button" @click="openCustomAmountPaymentDialog()"
                          v-if="subscription.subscriptionStatus == 'active'"
                          class="btn btn-md btn-primary text-white font-weight-bold">Make Payment</button>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row pt-4">
                  <div class="col">
                    <h4 class="font-weight-normal mb-4 text-center">Choose a plan that suits you the best.</h4>
                  </div>
                </div>
                <div class="row pricing-table">
                  <div class="col-md-4 col-xl-4 grid-margin stretch-card pricing-card">
                    <div class="card border-primary border pricing-card-body">
                      <div class="text-center pricing-card-head">
                        <h3>Basic Plan</h3>
                        <h3 class="font-weight-normal mb-4">$10/Month</h3>
                      </div>
                      <ul class="list-unstyled plan-features">
                        <li>2 Ussd Apps</li>
                        <li>50 Screen Menus</li>
                        <li>50,000 Requests Monthly</li>
                        <li>Api Calls support</li>
                        <li>Bulk Sms (1000 free sms)</li>
                        <li>Connect Any external shortcode</li>
                      </ul>
                      <div class="wrapper">
                        <button type="button" class="btn btn-outline-primary btn-block"
                          v-if="currentPlan == 'BASIC' || currentPlan == 'TRIAL'"
                          @click="openPaymentConfirmation('BASIC')">Make Payment
                        </button>
                        <button type="button" class="btn btn-outline-primary btn-block"
                          v-if="currentPlan != 'BASIC' && currentPlan != 'TRIAL'"
                          @click="openPaymentConfirmation('BASIC', 'changePlan')">Change Plan
                        </button>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4 col-xl-4 grid-margin stretch-card pricing-card">
                    <div class="card border border-success pricing-card-body">
                      <div class="text-center pricing-card-head">
                        <h3 class="text-success">Standard Plan</h3>
                        <h3 class="font-weight-normal mb-4">$20/Month</h3>
                      </div>
                      <ul class="list-unstyled plan-features">
                        <li>10 Ussd Apps</li>
                        <li>Unlimited Menus</li>
                        <li>200,000 Requests Monthly</li>
                        <li>Api Calls support</li>
                        <li>Bulk Sms (2000 sms monthly)</li>
                        <li>Connect Any external shortcode</li>
                        <li>Application Support and development</li>
                        <li>5 digit ShortCode Extension</li>
                       </ul>
                      <div class="wrapper">
                        <button type="button" class="btn btn-outline-success btn-block"
                          v-if="currentPlan == 'STANDARD' || currentPlan == 'TRIAL'"
                          @click="openPaymentConfirmation('STANDARD')">Make Payment
                        </button>
                        <button type="button" class="btn btn-outline-success btn-block"
                          v-if="currentPlan != 'STANDARD' && currentPlan != 'TRIAL'"
                          @click="openPaymentConfirmation('STANDARD', 'changePlan')">Change Plan
                        </button>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4 col-xl-4 grid-margin stretch-card pricing-card">
                    <div class="card border border-primary pricing-card-body">
                      <div class="text-center pricing-card-head">
                        <h3>Premium</h3>
                        <h3 class="font-weight-normal mb-4">$30/Month</h3>
                      </div>
                      <ul class="list-unstyled plan-features">
                        <li>Unlimited Ussd Apps</li>
                        <li>Unlimited Menus</li>
                        <li>Unlimited Requests Monthly</li>
                        <li>Api Calls support</li>
                        <li>Bulk Sms (3000 sms monthly)</li>
                        <li>Connect Any external shortcode</li>
                        <li>3 digit ShortCode Extension</li>
                        <li>Application Support and development</li>
                       </ul>
                      <div class="wrapper">
                        <button type="button" class="btn btn-outline-primary btn-block"
                          v-if="currentPlan == 'PREMIUM' || currentPlan == 'TRIAL'"
                          @click="openPaymentConfirmation('PREMIUM')">Make Payment
                        </button>
                        <button type="button" class="btn btn-outline-primary btn-block"
                          v-if="currentPlan != 'PREMIUM' && currentPlan != 'TRIAL'"
                          @click="openPaymentConfirmation('PREMIUM', 'changePlan')">Change Plan
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="tab-pane fade" id="pills-history" role="tabpanel" aria-labelledby="pills-history-tab">
            <div class="media">
              <div class="table-responsive">
                <table class="table">
                  <thead>
                    <tr>
                      <th>Description</th>
                      <th>Date</th>
                      <th>Amount(USD)</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="item in paymentHistory" :key="item">
                      <td>{{ item.description }}</td>
                      <td>{{ item.date }}</td>
                      <td>{{ item.amount }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div>
      <div class="modal fade" id="prodgressDialog" tabindex="-1" role="dialog" aria-labelledby="ModalLabel"
        aria-hidden="true">
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="loader-demo-box1">
              <div class="dot-opacity-loader">
                <span></span>
                <span></span>
                <span></span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import api from "@/shared/menuApi";
import { useToast } from "vue-toastification";
function showSweetAlert(title, message, confirmCallback) {
  return swal({
    title: title,
    text: message,
    buttons: {
      cancel: {
        text: "Cancel",
        value: null,
        visible: true,
        className: "btn btn-danger",
        closeModal: true,
      },
      confirm: {
        text: "Confirm",
        value: true,
        visible: true,
        className: "btn btn-primary",
        closeModal: true
      }
    }
  }).then((value) => {
    if (confirmCallback) {
      confirmCallback(value)
    }
  });
}

function showSweetAlertWithAmountEntry(title, message, confirmCallback) {
  return swal({
    title: title,
    text: message,
    content: {
      element: "input",
      attributes: {
        placeholder: "Enter Amount (USD)",
        type: "number",
        class: 'form-control'
      },
    },
    buttons: {
      cancel: {
        text: "Cancel",
        value: null,
        visible: true,
        className: "btn btn-warning",
        closeModal: true,
      },
      confirm: {
        text: "Submit",
        value: true,
        visible: true,
        className: "btn btn-primary",
        closeModal: true
      }
    }
  }).then((value) => {
    if (confirmCallback) {
      confirmCallback(value)
    }
  });
}

function openInNewTab(url) {
  window.open(url, '_blank', 'noreferrer');
}

export default {
  name: "CreateApp",
  components: {
  },
  setup() {
    const toast = useToast();
    return { toast };
  },
  computed: {
    currentPlan() {
      if (this.subscription) {
        return this.subscription.plan
      }
      return 'TRIAL'
    }
  },
  data() {
    return {
      subscriptionTypes: {},
      subscription: null,
      paymentHistory: [],
      inputData: {},
      errors: {},
    };
  },
  methods: {
    getPaymentHistory() {
      api
        .doGet(`/api/subscription/paymentHistory`)
        .then((data) => {
          if (data) {
            console.log('PaymentHistory data>>', data);
            this.paymentHistory = data;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    openPaymentConfirmation(plan, action) {
      this.inputData.plan = plan;
      this.inputData.action = action || 'makePlanPayment';
      if (action == 'retry') {
        if (this.subscription && this.subscription.plan) {
          plan = this.subscription.plan;
          if (this.subscription.plan == "TRIAL") {
            plan = "BASIC";
          }
        }
      }
      let subscriptionType = this.subscriptionTypes[plan];
      let title = "Confirm Payment";
      let message = `Confirm payment of ${subscriptionType.currency} ${subscriptionType.price} for ${plan} plan`;
      if (action == 'changePlan') {
        title = "Confirm Plan Change";
        message = `Confirm subscription plan change to ${plan} PLAN. `;
        if (this.subscription) {
          let selectedSubscription = this.subscriptionTypes[plan];
          if (this.subscription.accountBalance == 0) {
            message += `You will have to make an additional payment of ${this.subscription.currency} ${selectedSubscription.price} `
          }
        }
      } else {
        title = "Confirm Payment";
        message = `Confirm payment of ${subscriptionType.currency} ${subscriptionType.price} for ${plan} plan`;
      }
      let vm = this;
      showSweetAlert(title, message, function (isConfirm) {
        console.log('Result from confirm dialog', isConfirm);
        if (action == 'changePlan') {
          if (isConfirm) {
            jQuery("#prodgressDialog").modal("show");
            vm.changePlan()
          }
        } else {
          if (isConfirm) {
            jQuery("#prodgressDialog").modal("show");
            vm.makeSubscriptionPayment()
          }
        }

      });
    },
    openCustomAmountPaymentDialog() {
      this.inputData.plan = this.subscription.plan;
      this.inputData.action = 'makeRepayment';
      let title = "Enter Amount to Pay";
      let message = `You have an active subscription. Additional amounts will be credited to your account for future billing.`;
      let vm = this;
      showSweetAlertWithAmountEntry(title, message, function (amountEntered) {
        if (amountEntered) {
          vm.inputData.amount = amountEntered;
          console.log('Result from confirm dialog', amountEntered);
          jQuery("#prodgressDialog").modal("show");
          vm.makeRepayment();
        }

      });
    },
    getCurrentSubscription() {
      let vm = this;
      api
        .doGet("/api/subscription/current")
        .then((response) => {
          console.log('The current subscription >>', response);
          if (response.status == "00") {
            vm.subscription = {};
            vm.subscription.plan = response.data.plan;
            vm.subscription.subscriptionStatus = response.data.subscriptionStatus;
            vm.subscription.currency = response.data.currency;
            vm.subscription.accountBalance = response.data.accountBalance;
          }
        })
        .catch((error) => {
          this.toast.error(error.message);
        })
    },
    getSubscriptionTypes() {
      api
        .doGet("/api/subscription/types")
        .then((response) => {
          console.log('getSubscriptionTypes Response>>', response);
          this.subscriptionTypes = response;
        })
        .catch((error) => {
          this.toast.error(error.message);
        })
    },
    makeSubscriptionPayment() {
      let payload = {
        plan: this.inputData.plan,
      }
      api
        .doPost("/api/subscription/makePayment", payload)
        .then((response) => {
          console.log('Response from server >>', response);
          if (response.status == "01") {
            setTimeout(function () {
              jQuery("#prodgressDialog").modal("hide");
            }, 3000);
            openInNewTab(response.redirectUrl);
          } else {
            jQuery("#prodgressDialog").modal("hide");
            this.toast.info(response.message)
          }
        })
        .catch((error) => {
          this.toast.error(error.message);
        });
    },
    makeRepayment() {
      if (this.inputData.amount == null) {
        return;
      }
      let payload = {
        plan: this.inputData.plan,
        amount: this.inputData.amount
      }
      api
        .doPost("/api/subscription/creditAccount", payload)
        .then((response) => {
          console.log('Response from creditAccount', response);
          console.log('Response from server >>', response);
          if (response.status == "01") {
            setTimeout(function () {
              jQuery("#prodgressDialog").modal("hide");
            }, 3000);
            openInNewTab(response.redirectUrl);
          } else {
            jQuery("#prodgressDialog").modal("hide");
            this.toast.info(response.message)
          }
        })
        .catch((error) => {
          this.toast.error(error.message);
        });
    },
    changePlan() {
      let payload = {
        plan: this.inputData.plan,
      }
      api
        .doPost("/api/subscription/changePlan", payload)
        .then((response) => {
          if (response.status == "01") {
            setTimeout(function () {
              jQuery("#prodgressDialog").modal("hide");
            }, 3000);
            openInNewTab(response.redirectUrl);
          } else if (response.status == "00") {
            jQuery("#prodgressDialog").modal("hide");
            this.toast.success(response.message)
          } else {
            jQuery("#prodgressDialog").modal("hide");
            this.toast.info(response.message)
          }
        })
        .catch((error) => {
          this.toast.error(error.message);
        });
    }
  },
  mounted() {
    this.getSubscriptionTypes();
    this.getCurrentSubscription();
    this.getPaymentHistory();
  },
};
</script>
