import * as axios from 'axios';
import { API } from './config';
import { logger } from './logger';
import { format } from 'date-fns';


function SessionHandler() {
  const self = this;
  self.sessionId = "";
  self.input = "";
  self.network = "";
  self.shortCode = "";
  self.msisdn = "23324111111";
  self.appId = '';
  self.initiate = async function (input, appId,sessionId,msisdn) {
    if(sessionId==null){
      sessionId = format(new Date(), 'yyyyMMddHHmmss');
    }
    this.sessionId = sessionId;
    this.inputType = "start";
    this.input = input;
    this.network = "MTN";
    if(msisdn!=null && msisdn!=""){
      this.msisdn = msisdn;
    }else{
      this.msisdn = "2331111111";
    }
    this.shortCode = input;
    self.appId = appId;
    const data = {
      session_id: this.sessionId,
      userdata: input,
      msisdn: this.msisdn,
      session_mode: this.inputType,
      network_id: this.network,
      appId: appId
    };
    let response = await makeRequest(data);
    return translateResponse(response);
  },
    self.sendReply = async function (input, end) {
      
      let inputType = "continue"
      if (end) {
        inputType = "end";
      }
      console.log('sendReply continue sessionId >>>' + this.sessionId);
      const data = {
        session_id: this.sessionId,
        userdata: input,
        msisdn: this.msisdn,
        session_mode: inputType,
        network_id: this.network,
        appId: self.appId
      };
      logger.info('sendReply payload >>>', data);
      let response = await makeRequest(data);
      logger.info('sendReply response >>>', response);
      return translateResponse(response);
    }
}

function translateResponse(data) {
  const resp = {
    responseText: data.text,
    responseType: data.session_mode,
  }
  if (data.error) {
    resp.responseText = data.message;
    resp.responseType = 'end';
  }
  return resp;
}

const makeRequest = async function (data) {
  let enpoint = '/ussd-emulator/' + data.appId;
  let payload = data;
  logger.info('Payload to be sent', payload);
  try {
    const response = await axios.post(`${API}${enpoint}`, payload);
    logger.info('Response fron server', response);
    return response.data;
  } catch (error) {
    logger.info('The error ecountered >>' + error);
    const resp = { status: "03", message: "Error making webservice call : " + error, error: true };
    logger.info('The error response >>', resp);
    return resp;
  }
};

const sessionHandler = new SessionHandler();
console.log('Session mobile ==' + sessionHandler.msisdn);
export const api = {
  initiate: sessionHandler.initiate,
  sendReply: sessionHandler.sendReply
  // setMobileNumber :function(mobile){
  //    console.log("Setting mobile number to "+mobile);
  //    sessionHandler.msisdn=mobile;
  // }
};
