<template>
  <div>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <h4 style="padding: 20px">Application Settings</h4>
          </div>
          <div class="card-body">
            <form class="forms-sample">
              <div class="form-group row">
                <label class="col-sm-4 col-form-label">Application Url</label>
                <div class="col-md-8">
                  <div class="card card-inverse-primary">
                    <div class="card-body">
                      <div class="card-title font-weight-bold">Test Url</div>
                      <div class="card-text">
                        <div class="row">
                          <div class="col-1">
                            <div class="badge badge-pill badge-outline-primary">
                              <i class="fas fa-info-circle"></i>
                            </div>
                          </div>
                          <div class="col-11">
                            <span class="small">
                              The following url can be give to a third party
                              provider to forward traffic to this app for testing purposes only :
                            </span>
                            <br />
                            <strong class="bold strong">https://shortcodeafrica.com/ussdapp/{{
                                appData.appId
                            }}</strong>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <br>
                  <div class="card card-inverse-success">
                    <div class="card-body">
                      <div class="card-title font-weight-bold">Production Url</div>
                      <div class="card-text">
                        <div class="row">
                          <div class="col-1">
                            <div class="badge badge-pill badge-outline-success">
                              <i class="fas fa-info-circle"></i>
                            </div>
                          </div>
                          <div class="col-11">
                            <span class="small">
                              The following url can be give to a third party
                              provider to forward traffic to this app for production:
                            </span>

                            <br />
                            <strong class="bold strong">https://ussdapp.shortcodeafrica.com/ussdapp/{{
                                appData.appId
                            }}</strong>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>


                </div>
              </div>
              <div class="form-group row" v-if="msg != ''">
                <label class="col-sm-4 col-form-label"></label>
                <div class="col-sm-8">
                  <div class="alert alert-warning alert-dismissible fade show" role="alert">
                    <strong>{{ msg }}</strong>
                    <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                </div>
              </div>
              <div class="form-group row">
                <label class="col-sm-4 col-form-label">App Name</label>
                <div class="col-sm-8">
                  <input type="text" class="form-control" placeholder="App ame" v-model="appData.appName" />
                </div>
              </div>
              <div class="form-group row">
                <label class="col-sm-4 col-form-label">Description</label>
                <div class="col-sm-8">
                  <textarea name="" class="form-control" placeholder="Description" cols="30" rows="5"
                    v-model="appData.description"></textarea>
                </div>
              </div>
              <div class="form-group row">
                <label class="col-sm-4 col-form-label">Country</label>
                <div class="col-sm-8">
                  <div class="row">
                    <div class="input-group mb-3">
                      <select class="form-control" v-model="appData.country" style="
                          background-color: #2b2e4c;
                          color: #e9e6e6;
                          border: 1px solid #424351;
                        ">
                        <option value="ghana">Ghana</option>
                        <option value="nigeria">Nigeria</option>
                        <option value="swaziland">Swaziland</option>
                        <option value="kenya">Kenya</option>
                        <option value="sierra-leone">Sierra Leone</option>
                        <option value="rwanda">Rwanda</option>
                        <option value="liberia">Liberia</option>
                        <option value="gabon">Gabon</option>
                        <option value="guinea">Guinea</option>
                        <option value="gambia">Gambia</option>
                        <option value="malawi">Malawi</option>
                        <option value="burundi">Burundi</option>
                        <option value="mauritius">Mauritius</option>
                        <option value="eritrea">Eritrea</option>
                        <option value="dr-congo">DR Congo</option>
                        <option value="niger">Niger</option>
                        <option value="tunisia">Tunisia</option>
                        <option value="algeria">Algeria</option>
                        <option value="south-africa">South Africa</option>
                        <option value="cape-verde">Cape Verde</option>
                        <option value="ivory-coast">Côte d'Ivoire</option>
                        <option value="togo">Togo</option>
                        <option value="lesotho">Lesotho</option>
                        <option value="morocco">Morocco</option>
                        <option value="zimbabwe">Zimbabwe</option>
                        <option value="namibia">Namibia</option>
                        <option value="chad">Chad</option>
                        <option value="seychelles">Seychelles</option>
                        <option value="equitorial-guinea">
                          Equitorial Gunea
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>

              <div class="form-group row">
                <label class="col-sm-4 col-form-label"></label>
                <div class="col-sm-8">
                  <div class="row">
                    <div class="col-7">
                      <div class="row">
                        <div class="col-6">
                          <div class="form-check form-check-flat form-check-primary">
                            <label class="form-check-label">
                              <input type="checkbox" class="form-check-input" v-model="appData.useTemplateTag"
                                v-on:change="onTemplateTagCheckChange()" />
                              Is Template Formated
                              <i class="input-helper"></i></label>
                          </div>
                        </div>
                        <div class="col-6">
                          <span style="height: 40px; line-height: 40px">
                            <ToolTip class="float-left"
                              content="Shortcode contains data to extract for processing e.g. *900*{merchantCode}# means if user dials *900*12345# 12345 will be extracted into a variable for processing"
                              placement="right" />
                          </span>
                        </div>
                      </div>
                    </div>
                    <div class="col-4"></div>
                  </div>
                </div>
              </div>

              <div class="form-group row" v-if="appData.connectionMode == 'new-shortcode'">
                <label class="col-sm-4 col-form-label">ShortCode provisioned for this app.
                  <ToolTip
                    content="ShowCode we could provision for this app. Send us a chat if you are will like to change it"
                    placement="right" />
                </label>
                <div class="col-sm-8">
                  <input type="text" style="background-color: #2b2e4c" readonly class="form-control disabled"
                    v-model="appData.shortCode" />
                </div>
              </div>

              <div class="form-group row" v-if="appData.connectionMode == 'existing-shortcode'">
                <label class="col-sm-4 col-form-label">Custom Short Code
                  <ToolTip content="Enter the shortcode you got from your provider" placement="right" />
                </label>
                <div class="col-sm-8">
                  <input type="text" class="form-control" :placeholder="customShortCodePlaceholder"
                    v-model="appData.shortCode" />
                </div>
              </div>

              <div class="form-group row">
                <label class="col-sm-4 col-form-label"></label>
                <div class="col-sm-8 float-right">
                  <button class="btn btn-primary mt-0 text-white float-right" type="button" @click="updateApp()">
                    Save
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>

    <!-- <div class="row">
      <div class="col-12 grid-margin stretch-card">
        <div class="card" style="margin-top: 30px">
          <div class="card-header">
            <h4 style="padding: 20px">Security Headers</h4>
          </div>
          <div class="card-body">
            <div class="alert alert-primary">
              Secure your application endpoint by adding security headers to
              prevent unauthorized access. This security headers will be checked
              when you call the ussd endpoint
            </div>
            <form class="forms-sample" @submit.prevent="createApp">
              <div class="card-header">
                <h4 style="padding: 20px">
                  Basic Authentication <small>(Optional)</small>
                </h4>
              </div>
              <div class="row">
                <div class="col-md-5">
                  <div class="form-group">
                    <input
                      type="text"
                      class="form-control"
                      name="appName"
                      v-model="username"
                      id="appName"
                      placeholder="Username"
                    />
                  </div>
                </div>
                <div class="col-md-5">
                  <div class="form-group">
                    <input
                      type="text"
                      class="form-control"
                      name="appName"
                      v-model="password"
                      id="appName"
                      placeholder="Password"
                    />
                  </div>
                </div>
                <div class="col-md-2">
                  <button type="submit" class="btn btn-primary me-2">
                    <i class="ti-save"></i>
                  </button>
                </div>
              </div>

              <div class="row">
                <div class="card-header">
                  <h4 style="padding: 20px">
                    Add Additional Security Headers <small>(Optional)</small>
                  </h4>
                </div>
              </div>
              <div class="row">
                <div class="col-md-5">
                  <div class="form-group">
                    <input
                      type="text"
                      class="form-control"
                      name="appName"
                      v-model="key"
                      id="appName"
                      placeholder="Key"
                    />
                  </div>
                </div>
                <div class="col-md-5">
                  <div class="form-group">
                    <input
                      type="text"
                      class="form-control"
                      name="appName"
                      v-model="value"
                      id="appName"
                      placeholder="Value"
                    />
                  </div>
                </div>
                <div class="col-md-1">
                  <button type="submit" class="btn btn-primary">
                    Generate Key
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div> -->
  </div>
</template>
<script>
import Topnav from "../components/topnav.vue";
import Sidebar from "../components/sidebar.vue";
import { useToast } from "vue-toastification";
import ToolTip from "@/components/tooltipComponent.vue";
import api from "@/shared/menuApi";
export default {
  name: "CreateApp",
  components: {
    Topnav,
    Sidebar,
    ToolTip,
  },
  setup() {
    // Get toast interface
    const toast = useToast();
    return { toast };
  },
  data() {
    return {
      appData: {},
      msg: "",
      customShortCodePlaceholder: "e.g. *250*777# ",
      shortCodePlaceholder: "e.g. 666#",
    };
  },
  methods: {
    updateApp() {
      if (this.validateFields()) {
        let payload = Object.assign({}, this.appData);
        const vm = this;
        console.log("The payload to update app >>", payload);
        api
          .updateApp(payload)
          .then((response) => {
            console.log("Create app resposne>>", response);
            if (response.status == "00") {
              vm.toast.success(response.message);
              setTimeout(function () {
                vm.$router.push("/app-list");
              }, 1000);
            } else {
              vm.msg = response.message;
            }
          })
          .catch((error) => {
            this.toast.error(error.message);
          });
      }
    },
    validateFields() {
      if (
        this.appData.appName == "" ||
        this.appData.description == "" ||
        this.appData.shortCode == ""
      ) {
        this.msg = "Please complete all fields";
        return false;
      }
      if (this.appData.shortCode == null || this.appData.shortCode == "") {
        this.msg = "Please enter Short Code";
        return false;
      }
      if (this.appData.shortCode.indexOf("#") == -1) {
        this.msg = "Shortcode must end with '#'";
        return false;
      }
      return true;
    },
    onTemplateTagCheckChange() {
      console.log("The check changed", this.appData.isTemplateTag);
      if (this.appData.useTemplateTag) {
        this.customShortCodePlaceholder =
          "e.g. *250*{amount}#, *250*200*{merchantCode}# ";
        this.shortCodePlaceholder =
          "e.g. 250*{amount}#, 250*200*{merchantCode}# ";
      } else {
        this.customShortCodePlaceholder = "e.g. *250*777#";
        this.shortCodePlaceholder = "e.g. 666#, 5*500#, 200*600*345#";
      }
    },
    async getAppInfo() {
      let response = await api.getAppById(this.$route.params.id);
      console.log("The app info response  >>>", response);
      if (response.status == "00") {
        this.appData = response.data;
      }
    },
  },
  mounted() {
    if (!localStorage.getItem("token")) {
      this.$router.push("/login");
    }
    this.getAppInfo();
  },
};
</script>
<style scoped>
#submitBtn {
  margin-top: 20px;
}

#error {
  margin-top: 40px;
}

.disabled {
  color: #697b7b;
  background-color: #2b2e4c;
}
</style>
