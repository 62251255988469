<template>
    <div>
  <button class="btn btn-primary btn-sm" @click="addValidation(0)" style="margin-right:20px; color:#fff"><b> +</b></button>
<br>
        <div v-for="(nav, i) in inputValidations" :key="i">
            <br >
        <div class="row" >
                <div class="col-md-3" >
                    <div class="form-group" >
                        <label>Validation Method</label>
                        <select class="form-control"  :id="`symbols${nav.id}`" v-model="nav.validationMethod"  style="color:#000">
                            <option value="isAlpha">Is Alpha Numeric</option>
                            <option value="isNumeric">Is Numeric</option>
                            <option value="gte">Greater than</option>
                            <option value="gte">Greater than or equal</option>
                            <option value="lte">Less than</option>
                            <option value="lt">Less than or equal</option>
                            <option value="eq">Equals</option>
                            <option value="valueIn">Value In</option>
                            <option value="valueNotIn">Value Not In</option>
                            <option value="startsWith">Starts With</option>
                            <option value="endsWith">Ends With</option>
                            <option value="regexp">Regular expression</option>
                            <option value="notRegexp">Not Regular expression</option>
                            <option value="between">Between</option>
                            <option value="contains">Value</option>
                            <option value="notBetween">Not between</option>
                            <option value="javascript">Javascript</option>
                        </select>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="form-group" :id="`input${nav.id}`">
                        <label for="">Compare value</label>
                        <input type="text" class="form-control form-control-sm" v-model="nav.compareValue" placeholder="1,2,3" >
                    </div>
                </div> 
                <div class="col-md-3">
                <div class="form-group" :id="`input${nav.id}`">
                    <label for="">Error message if failed</label>
                    <input type="text" class="form-control form-control-sm" v-model="nav.errorMessage" placeholder="Validation Error Message">
                </div>
             </div>
                <div class="col-md-2">
                <!-- add and delete icon     -->
                    <div class="form-group"  style="margin-top:30px">
                        <button type="button" class="btn btn-primary btn-sm " style="margin-right:20px; color:#fff" @click="addValidation(i)">
                            <b>+</b>
                        </button>
                         <button type="button" class="btn btn-danger btn-sm" style="margin-right:20px; color:#fff" @click="removeValidation(i)">
                            <b>-</b>
                        </button>
                    </div>
                </div>
        </div>
        <div class="row" v-show="nav.validationMethod=='javascript'">
            <div class="col-md-12">   
                <div class="form-group">
                    <label class="h4">Javascript Validation Function</label>
                    <Editor v-model="nav.validationFunction"/>
                    <!-- <textarea class="form-control" id="" cols="30" rows="10" v-model="nav.validationFunction"></textarea> -->
                 </div>
            </div>
        </div>
    </div>
  </div>
</template>
<script>
import Editor from './editorComponent.vue'
export default {
     props: {
         inputValidations:{
          type:Array,
          default:[]
      }
     },
    components:{
        Editor
    },
    methods:{
        addValidation(){
            this.inputValidations.push({
                errorMessage:'',
                validationMethod:'isNumeric',
                compareValue:'',
                validationFunction:''
            })
        },
        removeValidation(index){
            this.inputValidations.splice(index,1)
        },
        getInputValidations(){
            let payload = [].concat(this.inputValidations);
            return payload;
        }
    }
}
</script>