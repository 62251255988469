<template>
  <div>
    <div class="card">
      <div class="card-body">
        <div class="header mt-md-5">
          <div class="header-body">
            <div class="row align-items-center">
              <div class="col"></div>
            </div>
            <!-- / .row -->
          </div>
        </div>

        <!-- / .row -->
        <!-- Card -->
        <div class="row ">
          <div class="col-12 col-md-12">
            <!-- Card -->
            <div class="blockquote blockquote-primary">
              <div class="card-body">
                <div class="row align-items-center">
                  <div class="col">
                    <!-- Title -->
                    <!-- Heading -->
                    <h3 class="mb-2">Hello, Welcome to ShortCode Africa</h3>
                    <small class="text-muted">
                      Create and deploy your USSD app in a few simple steps
                    </small>
                  </div>
                  <div class="col-auto">
                    <!-- Icon -->
                    <router-link to="/create-app" class="btn btn-lg btn-success text-white font-weight-bold">New App
                    </router-link>
                  </div>
                </div>
                <!-- / .row -->
              </div>
            </div>
          </div>
        </div>

        <!-- / .row -->
      </div>

    </div>
    <br>
    <div class="mt-2 mb-0">
      <h4 class="display-4">
        Things you can build
      </h4>
      <small class="text-muted">Clone one the following apps, modify and deploy or create a new app</small>
    </div>
    <hr>

    <div class="row">
      <FeaturedTemplates :list="templates" />
    </div>

  </div>

</template>
<script>

import FeaturedTemplates from "@/components/featuredTemplates";
import baseUrl from "@/shared/baseUrl";
export default {
  name: "CreateApp",
  components: {
    FeaturedTemplates
  },
  name: "app-templates",
  data() {
    return {
      templates: [],
    };
  },
  methods: {
    getTemplates() {
      const vm = this;
      baseUrl
        .get("/featuredTemplates")
        .then((response) => {
          if (response.data && response.data.status == '00') {
            console.log('The templates', response.data);
            vm.templates = [...response.data.data];
          }
        })
        .catch((error) => {
          console.log('There was an error>>>', error);
        });
    },
  },
  mounted() {
    this.getTemplates();
  },
};
</script>
