<template>
  <div class="card">
    <div class="card-header">
      <h4 style="padding: 10px">Create New Ussd App</h4>
    </div>
    <div class="card-body">
      <form class="forms-sample" @submit.prevent="createApp">
        <div class="form-group row" v-if="msg != ''">
          <label class="col-sm-4 col-form-label"></label>
          <div class="col-sm-8">
            <div class="alert alert-warning alert-dismissible fade show" role="alert">
              <strong>{{ msg }}</strong>
              <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-sm-4 col-form-label">App Name</label>
          <div class="col-sm-8">
            <input type="text" class="form-control" placeholder="Give your app a name for identification"
              v-model="createAppData.appName" />
          </div>
        </div>
        <div class="form-group row">
          <label class="col-sm-4 col-form-label">Description</label>
          <div class="col-sm-8">
            <textarea name="" class="form-control" placeholder="Briefly describe what your app does" cols="30" rows="5"
              v-model="createAppData.description"></textarea>
          </div>
        </div>

        <div class="form-group row">
          <label class="col-sm-4 col-form-label">Country
            <ToolTip content="Which country will users dial your shortcode from" placement="right" />
          </label>
          <div class="col-sm-8">
            <div class="row">
              <div class="input-group mb-3">
                <select class="form-control" v-model="createAppData.country" style="
                    background-color: #2b2e4c;
                    color: #e9e6e6;
                    border: 1px solid #424351;
                  ">
                  <option value="">..Select Country..</option>
                  <option value="ghana">Ghana</option>
                  <option value="nigeria">Nigeria</option>
                  <option value="swaziland">Swaziland</option>
                  <option value="kenya">Kenya</option>
                  <option value="sierra-leone">Sierra Leone</option>
                  <option value="rwanda">Rwanda</option>
                  <option value="liberia">Liberia</option>
                  <option value="gabon">Gabon</option>
                  <option value="guinea">Guinea</option>
                  <option value="gambia">Gambia</option>
                  <option value="malawi">Malawi</option>
                  <option value="burundi">Burundi</option>
                  <option value="mauritius">Mauritius</option>
                  <option value="eritrea">Eritrea</option>
                  <option value="dr-congo">DR Congo</option>
                  <option value="niger">Niger</option>
                  <option value="tunisia">Tunisia</option>
                  <option value="algeria">Algeria</option>
                  <option value="south-africa">South Africa</option>
                  <option value="cape-verde">Cape Verde</option>
                  <option value="ivory-coast">Côte d'Ivoire</option>
                  <option value="togo">Togo</option>
                  <option value="lesotho">Lesotho</option>
                  <option value="morocco">Morocco</option>
                  <option value="zimbabwe">Zimbabwe</option>
                  <option value="namibia">Namibia</option>
                  <option value="chad">Chad</option>
                  <option value="seychelles">Seychelles</option>
                  <option value="equitorial-guinea">Equitorial Gunea</option>
                </select>
              </div>
            </div>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-sm-4 col-form-label">How do you want to connect this app</label>
          <div class="col-sm-8">
            <div class="form-group mb-0">
              <div class="form-check">
                <label class="form-check-label">
                  <input type="radio" class="form-check-input" name="connectionMode" value="new-shortcode"
                    v-model="createAppData.connectionMode" checked="checked" />
                  I want a new shortcode from ShortcodeAfrica platform
                  <i class="input-helper"></i></label>
              </div>
              <div class="form-check">
                <label class="form-check-label">
                  <input type="radio" class="form-check-input" name="connectionMode"
                    v-model="createAppData.connectionMode" value="existing-shortcode" />
                  I have an existing shortcode I want to connect from another
                  provider
                  <i class="input-helper"></i></label>
              </div>
            </div>
          </div>
        </div>
        <div class="form-group row" v-if="createAppData.connectionMode == 'existing-shortcode'">
          <label class="col-sm-4 col-form-label"></label>
          <div class="col-md-8">
            <div class="card card-inverse-primary">
              <div class="card-body">
                <div class="card-text">
                  <div class="badge badge-pill badge-outline-primary">
                    <i class="fas fa-info-circle"></i>
                  </div>
                  We will generate a url to give to your provider for forwarding
                  traffic.
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-sm-4 col-form-label"></label>
          <div class="col-sm-8">
            <div class="row">
              <div class="col-7">
                <div class="row">
                  <div class="col-6">
                    <div class="form-check form-check-flat form-check-primary">
                      <label class="form-check-label">
                        <input type="checkbox" class="form-check-input" v-model="createAppData.useTemplateTag"
                          v-on:change="onTemplateTagCheckChange()" />
                        Is Template Formated
                        <i class="input-helper"></i></label>
                    </div>
                  </div>
                  <div class="col-6">
                    <span style="height: 40px; line-height: 40px">
                      <ToolTip class="float-left"
                        content="Shortcode contains data to extract for processing e.g. *900*{merchantCode}# means if user dials *900*12345# 12345 will be extracted into a variable for processing"
                        placement="right" />
                    </span>
                  </div>
                </div>
              </div>
              <div class="col-4"></div>
            </div>
          </div>
        </div>

        <div class="form-group row" v-if="createAppData.connectionMode == 'existing-shortcode'">
          <label class="col-sm-4 col-form-label">Custom Short Code
            <ToolTip content="Enter the shortcode you got from your provider" placement="right" />
          </label>
          <div class="col-sm-8">
            <input type="text" class="form-control" :placeholder="customShortCodePlaceholder"
              v-model="createAppData.customShortCode" />
          </div>
        </div>

        <div class="form-group row" v-if="createAppData.connectionMode == 'new-shortcode'">
          <label class="col-sm-4 col-form-label">Choose a shortcode for your app.
            <ToolTip
              content="Choose from our available top level shortcodes and a sub-shortcode. We will let you know if its available in a few minutes"
              placement="right" />
          </label>
          <div class="col-sm-8">
            <div class="row">
              <div class="input-group mb-3">
                <select name="" id="" v-model="createAppData.baseShortCode" style="width: 100px">
                  <option value="*800*">*800*</option>
                  <option value="*389*">*389*</option>
                  <option value="*790*">*790*</option>
                  <option value="*920*">*920*</option>
                </select>
                <input type="text" class="form-control" :placeholder="shortCodePlaceholder"
                  v-model="createAppData.shortCode" />
              </div>
            </div>
          </div>
        </div>

        <button type="submit" class="btn btn-primary mt-0 text-white float-right" id="submitBtn">
          Create App
        </button>
      </form>
    </div>
  </div>
</template>
<script>
import Topnav from "../components/topnav.vue";
import Sidebar from "../components/sidebar.vue";
import ToolTip from "@/components/tooltipComponent.vue";
import api from "@/shared/menuApi";
import { useToast } from "vue-toastification";

export default {
  name: "CreateApp",
  components: {
    Topnav,
    Sidebar,
    ToolTip,
  },
  setup() {
    // Get toast interface
    const toast = useToast();
    return { toast };
  },
  data() {
    return {
      createAppData: {
        appName: "",
        description: "",
        useTemplateTag: false,
        shortCode: "",
        country: "",
        connectionMode: "existing-shortcode",
        customShortCode: "",
      },
      msg: "",
      customShortCodePlaceholder: "e.g. *250*777# ",
      shortCodePlaceholder: "e.g. 666#",
    };
  },

  methods: {
    createApp() {
      let payload = Object.assign({}, this.createAppData);
      console.log("The payload >>", JSON.stringify(payload));
      if (this.validateFields()) {
        let payload = Object.assign({}, this.createAppData);
        console.log("The payload >>", JSON.stringify(payload));
        if (payload.connectionMode === "new-shortcode") {
          if (
            payload.baseShortCode !== "custom" &&
            payload.shortCode.indexOf("*") == 0
          ) {
            payload.shortCode = payload.shortCode.substring(1);
          }
          if (payload.baseShortCode !== "custom") {
            payload.shortCode = payload.baseShortCode + payload.shortCode;
          }
        } else {
          payload.shortCode = payload.customShortCode;
        }
        const vm = this;
        api
          .createApp(payload)
          .then((response) => {
            if (response.status === "00") {
              this.toast.success(response.message);
              this.createAppData.appName = "";
              this.createAppData.description = "";
              this.createAppData.useTemplateTag = false;
              this.createAppData.shortCode = "";
              setTimeout(function () {
                vm.$router.push("/app-list");
              }, 1000);
            } else {
              this.msg = response.message;
            }
          })
          .catch((error) => {
            this.toast.error(error.message);
          });
      }
    },
    validateFields() {
      if (
        this.createAppData.appName === "" ||
        this.createAppData.description === "" ||
        this.createAppData.country === ""
      ) {
        this.msg = "Please complete all fields";
        return false;
      }
      if (this.createAppData.connectionMode == "new-shortcode") {
        if (this.createAppData.baseShortCode == null) {
          this.msg = "Please select top level shortcode";
          return false;
        }
        if (this.createAppData.shortCode.indexOf("#") === -1) {
          this.msg = "Shortcode must end with '#'";
          return false;
        }
      } else {
        if (
          this.createAppData.customShortCode.match(/^\*(\d|\*)+#/i) === null
        ) {
          this.msg =
            "Shortcode must start with * and end with # . examples: *966#, *900*200#";
          return false;
        }
      }
      return true;
    },
    onTemplateTagCheckChange() {
      console.log("The check changed", this.createAppData.isTemplateTag);
      if (this.createAppData.useTemplateTag) {
        this.customShortCodePlaceholder =
          "e.g. *250*{amount}#, *250*200*{merchantCode}# ";
        this.shortCodePlaceholder =
          "e.g. 250*{amount}#, 250*200*{merchantCode}# ";
      } else {
        this.customShortCodePlaceholder = "e.g. *250*777#";
        this.shortCodePlaceholder = "e.g. 666#, 5*500#, 200*600*345#";
      }
    },
  },
};
</script>
<style scoped>
#submitBtn {
  margin-top: 20px;
}

#error {
  margin-top: 40px;
}
</style>
