<template>
  <div>
    <div class="modal fade" id="emulator-modal">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-body" style="text-align:center">
           <div class="form-group">
             <div class="col-sm-1">
             </div>
             <div class="col-sm-11">
               <span  v-for="(item, index) in menuText" :key="index">
               <div class="row">
                  {{ item }}
               </div>
            </span>
             </div>
           </div>
            <form>
              <div class="form-group">
                <input
                  type="text"
                  class="form-control"
                  placeholder=""
                  v-model="replyInput"
                  v-show="showInput==true"
                />
              </div>
              <div class="form-group">
                <button type="button" class="btn btn-secondary btn-md" @click="hideModal()">Cancel</button>
                <button type="button" v-show="showInput" class="btn btn-primary btn-md" style="margin-left:5px;color:white" @click="sendReply()">Send</button>
              </div>
            </form>
          </div>
        </div>
        <!-- /.modal-content -->
      </div>
      <!-- /.modal-dialog -->
    </div>
   </div>
</template>

<script>
import { api } from "@/shared/emulatorApi";
export default {
  name: "MenuDisplay",
  props: {},
  data() {
    return {
      menuText: [],
      replyInput: "",
      showInput :true,
    };
  },
  methods: {
    showModal(data) {
      this.showInput = true;
      if(data.responseText){
         this.setDisplayText(data.responseText);
          if(data.responseType==='end'){
            this.showInput=false;
        }
         window.jQuery("#emulator-modal").modal("show");
      }
    },
    hideModal() {
       window.jQuery("#emulator-modal").modal("hide");
        this.showInput = true;
    },
   async sendReply() {
        window.jQuery("#emulator-modal").modal("hide");
        console.log('the emaulator phone number is >>',this.$store.getters.getDialingPhone);
        // api.setMobileNumber(this.$store.getters.getDialingPhone);
        let response = await api.sendReply(this.replyInput)
        this.replyInput ='';
        this.setDisplayText(response.responseText);
         if(response.responseType){
          if(response.responseType==='end'){
            this.showInput=false;
          }else{
            this.showInput=true;
          }
        };
         setTimeout(function(){
          window.jQuery("#emulator-modal").modal("show");
        },500)
    },
    setDisplayText(str){
       this.menuText = str.split(/[?:\r\n|\r|\n]/);
    }
  },
};
</script>