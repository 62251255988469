<script>
import {defineComponent} from 'vue'
import * as dateFns from 'date-fns';
import baseUrl from "@/shared/baseUrl";
import menuApi from "@/shared/menuApi";

export default defineComponent({
  name: "Logs",
  props: {
    isModalShow: Boolean,
  },
  data() {
    return {
      selectedFilter: "all",
      currentApp: {},
      pullInterval:2500,
      filteredLogs:[],
      allLogs:[],
      lastTimeStamp:null,
      timeOut :null,
      unmounted:false
    };
  },
  methods: {
    filterLogs(filter) {
      this.selectedFilter = filter;
      if(filter==="all"){
        this.filteredLogs =[].concat(this.allLogs);
      }else{
        this.filteredLogs = this.allLogs.filter(log => log.logLevel === this.selectedFilter);
      }
    },
    clearLogs() {
      this.allLogs = [];
      this.filteredLogs = [];
    },
    pollForNewLogs(){
     let vm=this;
     if(this.unmounted){
       return;
     }
     this.timeOut = setTimeout(function (){
        vm.getAppLogs(function (){
          console.log("Polling for new logs>>")
          vm.pollForNewLogs();
        })
      },this.pullInterval)
    },
    addLogs(data){
      this.allLogs=this.allLogs.concat(data);
      if(this.selectedFilter==="all"){
        let len=data.length;
        for(let k=0;k<len;k++){
          this.filteredLogs.push(data[k]);
        }
      }else{
        this.filterLogs(this.selectedFilter);
      }
      const dataLen= data.length;
      if(dataLen > 0){
        const lastItem = data[dataLen-1];
        this.lastTimeStamp=lastItem.timestamp;
        //Scroll up when new logs are added
      }
    },
    getAppLogs(callback){
      let payload = {
        appId: this.currentApp.appId,
        timeStamp: this.lastTimeStamp//'20230920010130'
      }
      baseUrl
          .post(`/streamLogs`, payload).then((response) => {
            if(response.data.status==="00"){
             this.addLogs(response.data.data);
           }else{
             console.log("There was and error from the server >>"+response.data.message);
             this.filteredLogs.push({logLevel:"error",message:response.data.message});
           }
           if(callback){
              callback();
           }
      }).catch((error) => {
        this.error = error;
        console.log("The was an error");
        this.filteredLogs.push({logLevel:"error",message:"Error getting logs>>"+error});
        this.allLogs.push({logLevel:"error",message:"Error getting logs>>"+error});
        if(callback){
          callback();
        }
      });
    },
    async getCurrentApp(){
      let app = this.$store.getters.getCurrentApp;
      //console.log("The current app is>>>", app);
      if (app == null || app.appId == null) {
        //console.log("The route params id " + this.$route.params.appId);
        let response = await menuApi.getAppById(this.$route.params.appId);
        //console.log('The app is >>>',response);
        if (response.status == "00") {
          app = response.data;
        }
      }
      this.currentApp = app;
    },
    getLogColor(logLevel) {
      switch (logLevel) {
        case 'system':
          return '#fff';
        case 'info':
          return '#48dbfb';
        case 'error':
          return '#ff6b6b';
        case 'debug':
          return '#e67e22';
        default:
          return '#fff';
      }
    },
  },
  async mounted() {
    this.lastTimeStamp = dateFns.format(new Date(), 'yyyyMMddHHmmss');
    console.log("the initialized time stamp is >>",this.lastTimeStamp);
    await this.getCurrentApp();
    await this.getAppLogs();
    this.pollForNewLogs();
    // this.filteredLogs.push({logLevel:"info",message:""});
    // this.allLogs.push({logLevel:"info",message:""});
  },
  beforeUnmount() {
    // This code will run just before the component is unmounted
    console.log('Component is about to be unmounted');
    clearTimeout(this.timeOut);
    this.unmounted=true;
  }
})
</script>

<template>
  <div>
    <transition name="fade">
      <div v-if="isModalShow" class="logsModal">
        <div class="modal-content">
          <h5 class="modal-title">
            ACTION LOGS
          </h5>
          <div class="filterLogView">
            <button class="all" @click="filterLogs('all')">ALL</button>
            <button class="system" @click="filterLogs('system')">SYSTEM</button>
            <button class="info" @click="filterLogs('info')">INFO</button>
            <button class="error" @click="filterLogs('error')">ERROR</button>
            <button class="debug" @click="filterLogs('debug')">DEBUG</button>
            <button class="clear" @click="clearLogs()">CLEAR</button>
          </div>
          <div class="body-Body">
            <ul style="list-style:none;" class="ml-0 mr-0 pl-0 pr-0">
              <li class="ml-0" v-for="(log, index) in filteredLogs" :key="index" :style="{ color: getLogColor(log.logLevel) }">
                <div class="row">
                  <div class="col-1">
                    <span style="font-weight: bold;">  {{ index+1 }}.</span>  
                  </div>
                  <div class="col-11 pr-0 pl-0">
                    <span> {{ log.message }}</span>
                  </div>
                </div>
             
        
              </li>
            </ul>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<style scoped>
.logsModal {
  position: fixed;
  top: 0;
  right: 0;
  height: 100vh;
  width: 400px;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent backdrop */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
  transition: opacity 0.5s;
  animation: showPad 1s ease  1;
}
.modal-title{
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
  color: #fff;
}
.filterLogView{
  display: flex;
  align-items: center;
  margin-top: 10px;
}
.filterLogView button{
  color: #000;
  border: none;
  padding: 5px 10px;
  border-radius: 2px;
  margin-right: 10px;
  cursor: pointer;
  font-size: 10px;
}

.all {
  background-color: #11d33e;
}
.system {
  background-color: #8ea293;
}

.clear {
  background-color: #ffff;
}
.info {
  background-color: #48dbfb;
}
.error {
  background-color: #ff6b6b;
}
.debug {
  background-color: #f5c55f;
}

@keyframes showPad {
  0% {
    top: 50px;
    opacity: 0;
  }

  100% {
    top: 0px;
    opacity: 1;
  }
}

.modal-content {
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
  height: 100vh;
}

.body-Body{
  height: 100%;
  overflow-y: scroll;
  margin-top: 30px;
}
.body-Body p{
  font-size: 12px;
}

/* Transition effect */
.fade-enter-active, .fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter, .fade-leave-to {
  opacity: 0;
}
</style>