<template>
      <div class="row">
                        <div class="col-md-12">
                        <div class="card">
                            <div class="card-body">
                            <h4 class="card-title">SQL SCRIPT</h4>
                            <div class="row">
                                    <div class="col-md-4">
                                            <div class="form-group row">
                                            <label class="col-sm-5 col-form-label">Db Url</label>
                                            <div class="col-sm-7">
                                                <input
                                                        type="text"
                                                        class="form-control"
                                                        placeholder="192.168.0.1"
                                                        v-model="dbaccess.dburl"/>
                                                    </div>
                                            </div>
                                    </div>
                                    <div class="col-md-4">
                                            <div class="form-group row">
                                            <label class="col-sm-5 col-form-label">Db Type</label>
                                            <div class="col-sm-7">
                                               <select
                                                class="form-control text-dark"
                                                v-model="dbaccess.dialect">
                                                <option value="">..Select one..</option>
                                                <option value="postgres">Postgres</option>
                                                <option value="mysql">Mysql</option>
                                                <option value="mssql">Microsoft Sql</option>
                                            </select>
                                                    </div>
                                            </div>
                                    </div>
                                    <div class="col-md-4">
                                         <div class="form-check">
                                                <label class="form-check-label">
                                                <input
                                                    type="checkbox"
                                                    class="form-check-input"
                                                    v-model="dbaccess.secureConnection" />
                                                Secure Connection
                                                <i class="input-helper"></i></label>
                                            </div>
                                    </div> 
                                  </div>
                                <div class="row" >
                                <div class="col-md-4">
                                    <div class="form-group row">
                                    <label class="col-sm-5 col-form-label">Db User</label>
                                    <div class="col-sm-7">
                                        <input
                                        type="text"
                                        class="form-control"
                                        v-model="dbaccess.dbuser"
                                        placeholder="Username"
                                        />
                                    </div>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="form-group row">
                                    <label class="col-sm-5 col-form-label">Db Password</label>
                                    <div class="col-sm-7">
                                        <input
                                        type="password"
                                        class="form-control"
                                        v-model="dbaccess.dbpass"
                                        placeholder="Password"
                                        />
                                    </div>
                                    </div>
                                </div>
                                    <div class="col-md-4">
                                        <div class="form-group row">
                                            <label class="col-sm-5 col-form-label">Db Name</label>
                                            <div class="col-sm-7">
                                                <input
                                                        type="text"
                                                        class="form-control"
                                                        v-model="dbaccess.dbName"
                                                        placeholder="Db Name"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="form-group">
                                    <label class="h4">SQL Query</label>
                                    <Editor :content="dbaccess.query" language="sql"/>
                                         <v-ace-editor v-model:value="dbaccess.query"  :readonly="false" lang="sql" theme="monokai" style="height:300px"/>

                                        </div>
                                    </div>
                                </div>

                            </div>
                            
                        </div>
                        </div>
                    </div>
</template>

<script>
import { VAceEditor } from 'vue3-ace-editor';
import 'ace-builds/src-noconflict/mode-sql';
import 'ace-builds/src-noconflict/theme-monokai';
 
export default {
    props: {
      dbaccess:{
          type:Object,
          default:{dialect:""}
      }
  },
  components: {
    VAceEditor
  },
  methods: {
    
  },
}
</script>

<style lang="scss" scoped>

</style>