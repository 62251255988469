<template>
  <div>
    <ul>
      <div class="content-wrapper" v-if="LiveDatas">
        <div class="row">
          <div class="col-12">
            <div class="card">
              <div class="card-body">
                <div class="mt-5">
                  <div class="timeline">
                    <div
                      class="timeline-wrapper timeline-inverted timeline-wrapper-primary"
                      v-for="LD in liveDatas"
                      :key="LD.id"
                    >
                      <div class="timeline-badge"></div>
                      <div class="timeline-panel">
                        <div class="timeline-heading">
                          <h6 class="timeline-title">{{ LD.menuText }}</h6>
                        </div>
                        <div class="timeline-body">
                          <p>{{ LD.sessionId }}.</p>
                        </div>
                        <div
                          class="timeline-footer d-flex align-items-center flex-wrap"
                        >
                          <i class="ti-heart text-muted me-1"></i>
                          <span>{{ LD.network }}</span>
                          <span class="ml-md-auto font-weight-bold">{{
                            LD.mobile
                          }}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else>
        <div class="card">
          <div class="card-body">
            <div class="text-center">
              <img
                src="../../public/images/noresults.png"
                alt=""
                srcset=""
                style="width: 300px; border-radius: 50px"
              />
              <br />
              <br />
              <h3>No live data found</h3>
            </div>
          </div>
        </div>
      </div>
    </ul>
  </div>
</template>

<script>
import baseUrl from "@/shared/baseUrl";
export default {
  data() {
    return {
      appId: this.$route.params.id,
      liveDatas: [],
    };
  },
  methods: {
    async getLiveData() {
      console.log("The appId", "appId");
      console.log("The appId ", this.$route.params.id);
      baseUrl
        .get(`/dashboard/liveView/${this.appId}`)
        .then((res) => {
          console.log(res.data);
          this.liveData = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  async mounted() {
    console.log("the appId", this.$route.params.id);
    var vm = this;
    setTimeout(() => {
      vm.getLiveData();
    }, 2000);
  },
};
</script>

<style lang="scss" scoped></style>
