<template>
  <div class="row">
    <div class="col-md-12">
      <div class="card">
        <div class="card-body">
          <h4 class="card-title">CALL A REST API</h4>
          <ul class="nav nav-tabs" role="tablist">
            <li class="nav-item">
              <a
                class="nav-link active"
                data-bs-toggle="tab"
                :href="`#requestBody${id}`"
                role="tab"
                aria-controls="home-1"
                aria-selected="true"
                >Request Body</a
              >
            </li>
            <li class="nav-item">
              <a
                class="nav-link"
                data-bs-toggle="tab"
                :href="`#headers${id}`"
                role="tab"
                aria-controls="profile-1"
                aria-selected="false"
                >Request Headers</a
              >
            </li>
          </ul>
          <div class="tab-content">
            <div
              class="tab-pane fade show active"
              :id="`requestBody${id}`"
              role="tabpanel"
              aria-labelledby="home-tab"
            >
              <div class="input-group mb-3">
                <select name="" id="" v-model="restapi.method">
                  <option value="">..Method..</option>
                  <option value="GET">GET</option>
                  <option value="POST">POST</option>
                  <option value="PUT">PUT</option>
                  <option value="PATCH">PATCH</option>
                </select>
                <input
                  type="text"
                  class="form-control"
                  v-model="restapi.url"
                  placeholder="Enter request URL"
                />
                <select id="" v-model="restapi.contentType">
                  <option value="">Content Type</option>
                  <option value="application/json">application/json</option>
                  <option value="application/javascript">
                    application/javascript
                  </option>
                  <option value="application/x-www-form-urlencoded">
                    application/x-www-form-urlencoded
                  </option>
                  <option value="application/xml">application/xml</option>
                  <option value="text/xml">text/xml</option>
                  <option value="text/html">text/html</option>
                </select>
              </div>
              <div class="form-group">
                <h4 class="card-title">
                  REQUEST BODY
                  <RestActionPopOver />
                </h4>
                <v-ace-editor
                  v-model:value="restapi.payload"
                  lang="json"
                  theme="monokai"
                  style="height: 300px"
                />
              </div>
            </div>
            <div
              class="tab-pane fade"
              :id="`headers${id}`"
              role="tabpanel"
              aria-labelledby="profile-tab"
            >
              <div class="row">
                <div class="col-md-3" style="margin-top: 15px">
                  <div class="form-group">
                    <h5>Basic Authentication</h5>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <input
                      type="text"
                      class="form-control"
                      v-model="restapi.basicAuth.username"
                      placeholder="User Name"
                    />
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <input
                      type="password"
                      class="form-control"
                      v-model="restapi.basicAuth.password"
                      placeholder="Password"
                    />
                  </div>
                </div>
              </div>
              <div class="row">
                <hr class="text-center" style="width: 96%" />
              </div>

              <h4>Additional Headers</h4>
              <br />
              <button
                type="button"
                class="btn btn-primary btn-sm mb-5"
                style="margin-right: 20px; color: #fff"
                @click="addHeader(0)"
              >
                <b> +</b>
              </button>
              <div class="row" v-for="(header, k) in restapi.headers" :key="k">
                <div class="col-md-4">
                  <div class="form-group">
                    <input
                      type="text"
                      class="form-control form-control-sm"
                      v-model="header.headerKey"
                      placeholder="Header Key"
                    />
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <input
                      type="text"
                      class="form-control form-control-sm"
                      v-model="header.headerValue"
                      placeholder="Header Value"
                    />
                  </div>
                </div>
                <div class="col-md-4">
                  <button
                    type="button"
                    class="btn btn-danger btn-sm"
                    style="color: #fff"
                    @click="removeHeader(i)"
                  >
                    <b>-</b>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { VAceEditor } from "vue3-ace-editor";
import "ace-builds/src-noconflict/mode-json";
import "ace-builds/src-noconflict/theme-monokai";
import RestActionPopOver from "@/components/popoverComponentRestApiAction";
export default {
  props: {
    id: "",
    restapi: {
      type: Object,
      default: {},
    },
  },
  components: {
    VAceEditor,
    RestActionPopOver,
  },
  methods: {
    addHeader(index) {
      if (!this.restapi.headers) {
        this.restapi.headers = [];
      }
      this.restapi.headers.push({});
    },
    removeHeader(index) {
      this.restapi.headers.splice(index, 1);
    },
  },
};
</script>

<style lang="scss" scoped></style>
