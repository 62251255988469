<template>
  <Popper :content="content" :placement="placement">
    <i class="fas fa-info-circle"></i>
  </Popper>
</template>

<script>
import { defineComponent } from "vue";
import Popper from "vue3-popper";

export default defineComponent({
  components: {
    Popper,
  },
  props: {
    content: {
      type: String,
      default: "",
    },
    placement: {
      type: String,
      default: "bottom",
    },
  },
});
</script>
<style scoped>
:deep(.popper) {
  background: #2b5333;
  padding: 10px;
  border-radius: 20px;
  color: #fff;
  font-size: 0.8em;
}

:deep(.popper #arrow::before) {
  background: #20572c;
}

:deep(.popper:hover),
:deep(.popper:hover > #arrow::before) {
  background: #20572c;
}
</style>
