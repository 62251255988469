<template>
  <div>
    <div class="row">
      <div class="col-md-1"></div>
      <div class="col-md-2">
        <span class="form-group row">
          <div class="form-check1 form-check-flat form-check-primary">
            <label class="form-check-label">
              <input type="checkbox" class="form-check-input" v-model="alwaysGoto.active" />
              Always Goto
              <ToolTip content="No condition evaluated always redirect to selected menu after user clicks send" />
              <i class="input-helper"></i>
            </label>
          </div>
        </span>
      </div>

      <div class="col-md-4">
        <div class="form-group" v-show="alwaysGoto.active">
          <select class="form-control" style="color: #000" v-model="alwaysGoto.actionParam">
            <option value="">..Select Menu..</option>
            <option v-for="(option, index) in menusList" v-bind:value="option.uniqueId" :key="index">
              {{ option.displayText }}
            </option>
          </select>
        </div>
      </div>
    </div>
    <div class="row" v-for="(nav, index) in switchOperations" :key="index" v-show="alwaysGoto.active == false">
      <div class="col-md-2">
        <label></label>
        <span class="form-group row">
          <label class="h4">
            <strong> If Answer : </strong>
          </label>
        </span>
      </div>
      <div class="col-md-2">
        <div class="form-group">
          <label>Operator</label>
          <select class="form-control" :id="`symbols${nav.id}`" v-model="nav.operator" style="color: #000">
            <option value="eq">Equals</option>
            <option value="neq">not equal</option>
            <option value="gt">greater than</option>
            <option value="gte">greater than or equal</option>
            <option value="lt">less than</option>
            <option value="lte">less than or equal</option>
            <option value="valueIn">valueIn</option>
            <option value="valueNotIn">value Not In</option>
            <option value="startsWith">starts With</option>
            <option value="endsWith">ends With</option>
            <option value="contains">contains</option>
            <option value="regexp">Regular expression</option>
            <option value="notRegexp">Not Regular expression</option>
            <option value="between">between</option>
            <option value="notBetween">Not between</option>
          </select>
        </div>
      </div>
      <div class="col-md-2">
        <div class="form-group" :id="`input${nav.id}`">
          <label for="">Compare Value</label>
          <input type="text" class="form-control form-control-sm" v-model="nav.compareVal" placeholder="1 or 1,2,3" />
        </div>
      </div>
      <div class="col-md-2">
        <div class="form-group">
          <label for="">Action</label>
          <select class="form-control" v-model="nav.action" style="color: #000">
            <option>goto</option>
            <option>Terminate</option>
          </select>
        </div>
      </div>
      <div class="col-md-3">
        <div class="form-group">
          <label for="">Menu</label>
          <select class="form-control" style="color: #000" v-model="nav.actionParam">
            <option value="">..Select Menu..</option>
            <option v-for="(option, index) in menusList" v-bind:value="option.uniqueId" :key="index">
              {{ option.displayText }}
            </option>
          </select>
<!--          <MenuSelect :selected="nav.actionParam" @itemSelected="menuOptionSelected(nav, $event)"></MenuSelect>-->


<!--          <MenuSelect  name="test" v-model="nav.actionParam"></MenuSelect>-->
         </div>
      </div>
      <div class="col-md-1">
        <!-- add and delete icon     -->
        <div class="form-group" :id="`buttons${index}`" style="margin-top: 30px">
          <button type="button" class="btn btn-primary btn-sm" style="margin-right: 20px; color: #fff"
            @click="addNavigation(index)">
            <b> +</b>
          </button>
          <button type="button" class="btn btn-danger btn-sm" style="color: #fff" @click="removeNavigation(index)">
            <b>-</b>
          </button>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-1"></div>
      <div class="col-md-1 pl-0">
        <button class="btn btn-primary btn-sm float-left" @click="addNavigation(0)" v-show="alwaysGoto.active == false"
          style="margin-right: 20px; color: #fff">
          <b> + </b>
        </button>
      </div>
    </div>
  </div>
</template>
<script>

import ToolTip from "@/components/tooltipComponent.vue";
import MenuSelect from "@/components/switchOperationsMenuSelect.vue";
export default {
  components: {
    ToolTip,
    MenuSelect
  },
  props: {
    switchOperations: {
      type: Array,
      default: [],
    },
  },
  data() {
    return {
      alwaysGoto: {
        active: false,
        action: "goto",
        operator: "always",
        actionParam: "",
      },
    };
  },
  computed: {
    menusList() {
      return this.$store.getters.getMenusLite;
    },
  },
  mounted() {
    this.reMount();
  },
  methods: {
    addNavigation(index) {
      if (index > 0) {
        this.switchOperations.splice(index, 0, {
          action: "goto",
          operator: "eq",
        });
      } else {
        this.switchOperations.push({ action: "goto", operator: "eq" });
      }
    },
    reMount(){
      this.alwaysGoto.active = false;
      setTimeout(() => {
        console.log("the switch operations are ",JSON.stringify(this.switchOperations))
        if (
            this.switchOperations.length == 1 &&
            this.switchOperations[0].operator == "always"
        ) {
          this.alwaysGoto.active = true;
          this.alwaysGoto.operator =  "always";
          this.alwaysGoto.actionParam =   this.switchOperations[0].actionParam;
          this.switchOperations.splice(0,1);
        }
      }, 1000)
    },
    menuOptionSelected(nav, eventData) {
       nav.actionParam = eventData; //set the actionParam call by reference
    },
    removeNavigation(index) {
      if (this.switchOperations.length) {
        this.switchOperations.splice(index, 1);
      }
    },
    getSwitchOperations() {
      const array = [];
      if (this.alwaysGoto.active) {
        array.push(this.alwaysGoto);
        return array;
      }
      for (let k = 0; k < this.switchOperations.length; k++) {
        if (
          this.switchOperations[k].compareVal == "" &&
          this.switchOperations[k].action == ""
        ) {
          continue;
        }
        array.push(this.switchOperations[k]);
      }
      console.log("Get switch operations returned>>>",JSON.stringify(this.switchOperations))
      return array;
    },
  },
};
</script>
