<template>
     <div>
        <div class="row">
              <div class="col-md-3 grid-margin stretch-card">
                <div class="card">
                  <div class="card-body">
                    <p class="card-title text-md-center text-xl-left h3"  style="font-size:1.2em">Connected Users</p>
                    <div class="d-flex flex-wrap justify-content-between justify-content-md-center justify-content-xl-between align-items-center">
                      <h3 class="mb-0 mb-md-2 mb-xl-0 order-md-1 order-xl-0">{{connectedSessions}}</h3>
                      <i class="fa fa-users icon-md text-muted mb-0 mb-md-3 mb-xl-0"></i>
                    </div>  
                    <p class="mb-0 mt-2 text-success">3 minutes<span class="text-light ms-1"><small></small></span></p>
                  </div>
                </div>
              </div>
              <div class="col-md-3 grid-margin stretch-card">
                <div class="card">
                  <div class="card-body">
                    <p class="card-title text-md-center text-xl-left h3" style="font-size:1.2em">
                         Transactions 
                      </p>
                    <div class="d-flex flex-wrap justify-content-between justify-content-md-center justify-content-xl-between align-items-center">
                      <h3 class="mb-0 mb-md-2 mb-xl-0 order-md-1 order-xl-0">{{transactions}}</h3>
                      <i class="fa fa-th icon-md text-muted mb-0 mb-md-3 mb-xl-0"></i>
                    </div>  
                    <p class="mb-0 mt-2 text-success"></p>
                  </div>
                </div>
              </div>
              <div class="col-md-3 grid-margin stretch-card">
                <div class="card">
                  <div class="card-body">
                    <p class="card-title text-md-center text-xl-left h3"  style="font-size:1.2em">Total Users</p>
                    <div class="d-flex flex-wrap justify-content-between justify-content-md-center justify-content-xl-between align-items-center">
                      <h3 class="mb-0 mb-md-2 mb-xl-0 order-md-1 order-xl-0">{{totalUsers}}</h3>
                      <i class=" fa fa-user icon-md text-muted mb-0 mb-md-3 mb-xl-0"></i>
                    </div>  
                    <p class="mb-0 mt-2 text-success">Unique Users</p>
                  </div>
                </div>
              </div>
              <div class="col-md-3 grid-margin stretch-card">
                <div class="card">
                  <div class="card-body">
                    <p class="card-title text-md-center text-xl-left h3"  style="font-size:1.2em">Total Menus</p>
                    <div class="d-flex flex-wrap justify-content-between justify-content-md-center justify-content-xl-between align-items-center">
                      <h3 class="mb-0 mb-md-2 mb-xl-0 order-md-1 order-xl-0">{{menuCount}}</h3>
                      <i class="fa fa-server icon-md text-muted mb-0 mb-md-3 mb-xl-0"></i>
                    </div>  
                    <p class="mb-0 mt-2 text-success"><span class="text-light ms-1"><small></small></span></p>
                  </div>
                </div>
              </div>
        </div>

 
      </div>
</template>
 

<script>
import api from "@/shared/menuApi";
   export default {
    data() {
      return {
        connectedSessions:0,
        transactions:0,
        totalUsers:0,
        menuCount:0,
        refreshInterval:null
      }
    },
    methods: {
      async getDashboardData() {
       let data = await api.getDashboardData(this.$route.params.appId);
       console.log('The data is >>',data);
       if(data && Object.keys(data).length>3){
         this.connectedSessions=data.connectedSessions;
         this.transactions=data.transactions;
         this.totalUsers=data.totalUsers;
         this.menuCount=data.menuCount;
       }
      }
    },
    mounted () {
      let vm=this;
      this.getDashboardData();
      this.refreshInterval = setInterval(function() {
        vm.getDashboardData()
      },6000);
    },
    deactivated() {
      console.log('Calling BEFORE DESTROY >>>>>>>');
      clearInterval(this.refreshInterval);
    },
    unmounted () {
      console.log('Calling BEFORE DESTROY >>>>>>>');
      clearInterval(this.refreshInterval);
    }
  }
</script>