<template>
  <nav class="sidebar sidebar-offcanvas" id="sidebar">
    <ul class="nav">
      <li class="nav-item">
        <router-link class="nav-link" to="/get-started">
          <i class="fa fa-solid fa-list-alt menu-icon" style="margin-right: 6px"></i>
          <span class="menu-title">Get Started</span>
        </router-link>
      </li>
      <li class="nav-item">
        <router-link class="nav-link" to="/create-app">
          <i class="fa fa-solid fa-mobile-screen-button menu-icon" style="margin-right: 10px"></i>
          <span class="menu-title">Create App</span>
        </router-link>
      </li>
      <li class="nav-item">
        <router-link class="nav-link" to="/app-list">
          <i class="fa fa-solid fa-mobile-retro menu-icon" style="margin-right: 10px"></i>
          <span class="menu-title">My USSD Apps</span>
        </router-link>
      </li>
      <li class="nav-item">
        <router-link class="nav-link" to="/app-templates">
          <i class="fa fa-solid fa-magnifying-glass-arrow-right menu-icon" style="margin-right: 6px"></i>
          <span class="menu-title">Templates</span>
        </router-link>
      </li>
      <li class="nav-item">
        <router-link class="nav-link" to="/buy-shortcode">
          <i class="fa fa-solid fa-mobile-screen-button menu-icon" style="margin-right: 10px"></i>
          <span class="menu-title">Buy USSD ShortCode</span>
        </router-link>
      </li>
      <li class="nav-item">
        <router-link class="nav-link" to="/my-shortcodes">
          <i class="fa fa-solid fa-mobile-screen-button menu-icon" style="margin-right: 10px"></i>
          <span class="menu-title">My USSD Extensions</span>
        </router-link>
      </li>
      <li class="nav-item">
        <router-link class="nav-link" to="/account-billing">
          <i class="fa fa-solid fa-money-bill menu-icon" style="margin-right: 6px"></i>
          <span class="menu-title">Account Billing</span>
        </router-link>
      </li>
    </ul>
  </nav>
</template>
