<template>
  <div>
    <ul>
      <div class="content-wrapper">
        <div class="row">
          <div class="col-12">
            <div class="card">
              <div class="card-body">
                <div class="row">
                  <div class="col-12">
                    <form>
                      <div class="form-group row">
                        <label class="col-sm-4 col-form-label"></label>
                        <div class="col-sm-2">
                          <div class="form-check">
                            <label class="form-check-label">
                              <input
                                type="radio"
                                class="form-check-input"
                                name="searchBy"
                                value="date"
                                checked=""
                                v-model="searchBy" />
                              Search By Date
                              <i class="input-helper"></i
                            ></label>
                          </div>
                        </div>
                        <div class="col-sm-2">
                          <div class="form-check">
                            <label class="form-check-label">
                              <input
                                type="radio"
                                class="form-check-input"
                                name="searchBy"
                                value="query"
                                v-model="searchBy" />
                              Search By Word
                              <i class="input-helper"></i
                            ></label>
                          </div>
                        </div>

                        <div class="col-sm-2">
                          <div class="form-check">
                            <label class="form-check-label">
                              <input
                                type="checkbox"
                                class="form-check-input"
                                checked=""
                                v-model="flatResults" />
                              Flatten Results
                              <i class="input-helper"></i
                            ></label>
                          </div>
                        </div>
                      </div>
                      <div
                        class="form-group row d-flex"
                        v-if="searchBy == 'date'"
                      >
                        <label class="col-sm-4 col-form-label"
                          >Select Date Range</label
                        >
                        <div class="col-sm-4">
                          <Datepicker
                            v-model="date"
                            range
                            multiCalendars
                            autoApply
                            style="width: 98%; padding: 8px"
                            placeholder="Select Date"
                            :enableTimePicker="false"
                            dark
                          ></Datepicker>
                        </div>
                        <div class="col-sm-4">
                          <button
                            type="button"
                            class="btn btn-success text-white"
                            @click="searchRes"
                          >
                            Search
                            <span
                              class="circle-loader ml-2"
                              style="display: none"
                              id="loader"
                            ></span>
                          </button>
                        </div>
                      </div>
                      <div
                        class="form-group row d-flex"
                        v-if="searchBy == 'query'"
                      >
                        <label class="col-sm-4 col-form-label"
                          >Enter Word</label
                        >
                        <div class="col-sm-4">
                          <input
                            type="search"
                            class="form-control form-control-sm ms-3 p-4"
                            placeholder="Search Here..."
                            v-model="searchQuery"
                          />
                        </div>
                        <div class="col-sm-4">
                          <button
                            type="button"
                            class="btn btn-success text-white"
                            @click="searchRes"
                          >
                            Search
                            <span
                              class="circle-loader ml-2"
                              style="display: none"
                              id="loader"
                            ></span>
                          </button>
                        </div>
                      </div>
                    </form>
                    <p></p>
                  </div>

                  <div class="col-12" v-if="flatResults == false">
                    <table
                      id="searchResultsTable"
                      class="table dt-responsive"
                      cellspacing="0"
                      style="width: 100%"
                    >
                      <thead>
                        <tr>
                          <th>MOBILE</th>
                          <th>NETWORK</th>
                          <th>DATE</th>
                          <th>DETAILS</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div class="col-12" v-if="flatResults">
                    <div class="table-responsive" style="overflow-x: scroll">
                      <table
                        id="searchResultsFlatTable"
                        class="table no-footer"
                      ></table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ul>
  </div>
</template>

<script>
import baseUrl from "@/shared/baseUrl";
import Datepicker from "@/components/vue3-date-time-picker.js";
import "@/components/vue3-date-time-picker.css";
function extractRowsDataTable(headers, data) {
  var arr = [];
  data.forEach(function (item) {
    var obarr = [];
    headers.forEach(function (prop) {
      if (item[prop]) {
        obarr.push(item[prop]);
      } else {
        obarr.push("");
      }
    });
    arr.push(obarr);
  });
  return arr;
}

export default {
  components: {
    Datepicker,
  },
  data() {
    return {
      appId: this.$route.params.id,
      searchBy: "date",
      date: {},
      flatResults: false,
      searchQuery: "",
      noResults: false,
    };
  },
  methods: {
    searchRes() {
      const vm = this;
      try {
        let startDate = this.date[0];
        let endDate = this.date[1];
        const payload = {
          startDate: startDate,
          endDate: endDate,
          searchBy: this.searchBy,
          searchQuery: this.searchQuery,
          appId: this.appId,
          flatResults: this.flatResults,
        };
        console.log("Search Payload >>", payload);
        $("#loader").show();
        $("#resultsResponse").hide();
        this.searchResults = [];
        baseUrl.post("dashboard/search", payload).then((res) => {
          console.log("The response from search", res.data);
          $("#loader").hide();
          if (payload.flatResults) {
            vm.renderDataTableFlat(res.data);
          } else {
            if (res.data.length > 0) {
              vm.renderDataTable(res.data);
            }
          }
        });
      } catch (error) {
        $("#resultsResponse").hide();
        $("#loader").hide();
        this.searchBy = "";
        this.date.startDate = "";
        this.date.endDate = "";
      }
    },
    async renderDataTable(rows) {
      let dataRows = rows;
      console.log("The rows are >>>", rows);
      let table = $("#searchResultsTable").DataTable({
        destroy: true,
        paging: true,
        dom: "Bfrtip",
        buttons: ["copy", "csv", "excel"],
        data: dataRows,
        columns: [
          { data: "mobile" },
          { data: "network" },
          { data: "date" },
          {
            className: "showRowDetails",
            orderable: false,
            data: null,
            defaultContent: `<button type="button" class="btn btn-primary btn-rounded btn-icon text-white showRowDetails">
                                        <i class="fa fa-plus"></i>
                                    </button>`,
          },
        ],
        responsive: true,
        order: [[1, "asc"]],
      });
      let vm = this;
      $("#searchResultsTable tbody").on(
        "click",
        "td.showRowDetails",
        function () {
          var tr = $(this).closest("tr");
          var row = table.row(tr);
          if (row.child.isShown()) {
            row.child.hide();
            tr.removeClass("shown");
          } else {
            row.child(vm.getDetailsRow(row.data())).show();
            tr.addClass("shown");
          }
        }
      );
    },
    getDetailsRow(data) {
      let inputKeys = Object.keys(data.inputs);
      let table = "<table>";
      table += "<thead><tr>";
      table += ' <th scope="col">Input Field</th><th scope="col">Data</th>';
      table += "</tr> <tbody>";
      inputKeys.forEach(function (inputKey) {
        table +=
          ' <tr><th scope="row">' +
          inputKey +
          "</th><td>" +
          data.inputs[inputKey] +
          "</td></tr>";
      });
      table += "</tbody></table>";
      return table;
    },
    async renderDataTableFlat(data) {
      console.log("Rending tables flat>>>", data);
      let columns = [];
      if (data.rows.length) {
        data.headers.forEach(function (item) {
          columns.push({ title: item });
        });
      }
      let rows = extractRowsDataTable(data.headers, data.rows);
      let table = $("#searchResultsFlatTable").DataTable({
        dom: "Bfrtip",
        buttons: ["copy", "csv", "excel"],
        destroy: true,
        paging: true,
        data: rows,
        columns: columns,
        responsive: true,
        order: [[1, "asc"]],
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
