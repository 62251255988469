//# "editor.formatOnSave": false 
export default {
    snippets: [{
        description: "Dynamically add menu options based on input from user",
        codeSnippet: `
    switch (this.inRequest.input) {
        case "1":{
            this.menu.addListItem("1","300 Upfront annually");
            this.menu.addListItem("2","78 Upfront per quarter");
            break;
        }
        case "2":{
            this.menu.addListItem("1","480 Upfront annually ");
            this.menu.addListItem("2","125 Upfront per quarter");
            break;
        }
    }`
    },
    {
        description: "Branch to different page based on condition",
        codeSnippet: `
    if(this.session.inputs.userRegistered){
        this.goto("welcomeScreen");
    }else{
         this.goto("registerationPage");
    }
    `
    },
    {
        description: "Build payload for rest api and assign a session variable or return it to use in next action",
        codeSnippet: `
     const payload = {};
     payload.mobile = this.session.mobile;
     payload.paymentPlan = this.session.inputs.paymentPlanLabel;
     payload.amountPayable = this.session.inputs.amountPayable;
     payload.paymentStatus = "PENDING";
     payload.network= this.session.network;
     payload.channel= 'USSD';
     if(this.session.inputs.userInsured==="1"){
         payload.userIsInsured=true;
     }else{
         payload.userIsInsured=false;
     }

     /**
      * save value in session variable, value can get accessed at anytime during user's session
      * Can be retrieved with this.session.inputs.payload
      */
     this.session.setSessionValue('payload',JSON.stringify(payload));

      /**
      * save value session variable, expires as soon as user moves to the next menu screen
      * Can be retrieved with this.session.menuEnv.payload
      */
     this.session.setMenuEnvVariable('payload',JSON.stringify(payload));
     return payload;
    `
    }
    ]
}
