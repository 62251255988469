<template>
  <div class="row">
    <div class="col-md-12">
      <div class="card">
        <div class="card-body">
          <h4 class="card-title">
            WRITE JAVASCRIPT
            <JsActionPopOver />
          </h4>
          <div class="tab-content">
            <div
              class="tab-pane fade show active"
              id="home-1"
              role="tabpanel"
              aria-labelledby="home-tab"
            >
              <div class="form-group">
                <v-ace-editor
                  v-model:value="javascript.code"
                  :options="{ fontFamily: 'monospace' }"
                  lang="javascript"
                  theme="monokai"
                  style="height: 300px"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { VAceEditor } from "vue3-ace-editor";
import "ace-builds/src-noconflict/mode-javascript";
import "ace-builds/src-noconflict/theme-monokai";
import PopOver from "@/components/popoverComponent";
import JsActionPopOver from "@/components/popoverComponentJavascriptAction";
export default {
  props: {
    javascript: {
      type: Object,
      default: {},
    },
  },
  components: {
    VAceEditor,
    PopOver,
    JsActionPopOver,
  },
};
</script>

<style lang="css">
.ace_editor,
.ace_editor * {
  font-family: "Monaco", "Menlo", "Ubuntu Mono", "Droid Sans Mono", "Consolas",
    monospace !important;
  font-size: 12px !important;
  font-weight: 400 !important;
  letter-spacing: 0 !important;
}
</style>
