<template>
  <div>
    <v-select :value="selected" @input="selected = $event"   :placeholder="`..Select Menu..`" :options="menusList"
              style="max-height:0.8rem" :reduce="option => option.uniqueId" label="displayText">
      <template #selected-option-container="{ option, deselect, multiple, disabled }">
        <input class="vs__search" style="width:100%; padding:2px" type="text" :value="option.displayText" />
      </template>
    </v-select>
  </div>
</template>



<script>
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css';
export default {
  data() {
    return {
    }
  },
  props: {
    selected: {
      type: String,
      default: ""
    },
  },
  computed: {
    menusList() {
      return this.$store.getters.getMenusLite;
    },
  },
  components: {
    vSelect
  },
  watch: {
    selected(value, oldValue) {
      alert("Old Selected>>"+oldValue + " New Value>>"+value)
      this.$emit("itemSelected", value);
    }
  }
}
</script>

<style lang="css" scoped>
.vs__dropdown-toggle {
  background: #dfe5fb;
  border: none;
  color: #394066;
  text-transform: lowercase;
  font-variant: small-caps;
  height: 40px !important
}

>>> {
  --vs-controls-color: #664cc3;
  --vs-border-color: #664cc3;

  --vs-dropdown-bg: #282c34;
  --vs-dropdown-color: #cc99cd;
  --vs-dropdown-option-color: #cc99cd;

  --vs-selected-bg: #664cc3;
  --vs-selected-color: #eeeeee;

  --vs-search-input-color: #eeeeee;

  --vs-dropdown-option--active-bg: #664cc3;
  --vs-dropdown-option--active-color: #eeeeee;
}
</style>