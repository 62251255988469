<template>
      <div class="row">
                        <div class="col-md-12">
                        <div class="card">
                            <div class="card-body">
                            <h4 class="card-title">CUSTOM ACTION CODE</h4>
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="form-group row">
                                    <label class="col-sm-4 col-form-label">Action Type</label>
                                    <div class="col-sm-8">
                                        <select
                                                class="form-control text-dark" v-model="customAction.inheritsPlugin" style="color:black">
                                                <option value="javascript">Javascript</option>
                                                <option value="dbaccess">Database Query</option>
                                                <option value="restapi">Rest Api</option>
                                                <option value="sendsms">Send Sms Api</option>
                                                <option value="paymentprompt">Payment Prompt</option>
                                        </select>
                                    </div>
                                    </div>
                                </div>
                            </div>
                            <div class="tab-content">
                                <div
                                class="tab-pane fade show active"
                                role="tabpanel"
                                aria-labelledby="home-tab">
                                <div class="form-group">
                                      <v-ace-editor v-model:value="customAction.code"  lang="javascript" theme="monokai" style="height:300px"/>
                                 </div>
                                </div>
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>
</template>

<script>
import { VAceEditor } from 'vue3-ace-editor';
import 'ace-builds/src-noconflict/mode-javascript';
import 'ace-builds/src-noconflict/theme-monokai';
export default {
    props: {
      customAction:{
          type:Object,
          default:{}
      } 
  },
  components: {
    VAceEditor
  },
}
</script>

<style lang="scss" scoped>

</style>