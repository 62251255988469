

export const appTourMixin = {
    data() {
        return {
            steps: [
                {
                    target: '.design',  // We're using document.querySelector() under the hood
                    content: `Click to open  <strong>USSD App</strong> designer !`
                },
                {
                    target: '.appsettings',
                    content: 'Click to modify  <strong>App</strong> settings !'
                },
                {
                    target: '.deleteapp',
                    content: 'Click here to delete app!'
                },
                {
                    target: '.cloneapp',
                    content: 'Create a clone of this app by clicking here',
                    params: {
                        placement: 'top'
                    }
                }
            ]
        }
    },

};
