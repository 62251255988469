<template>
    <div>
        <div class="row">
            <div class="col-md-12 grid-margin">
                <div class="row">
                    <div class="col-8 mb-0 mb-xl-0">
                        <h4 class="font-weight-bold">Templates - Things you can build</h4>
                    </div>
                </div>
            </div>
        </div>
        <FeaturedTemplates :list="templates" />
    </div>
</template>

<script>

import FeaturedTemplates from "@/components/featuredTemplates";
import baseUrl from "@/shared/baseUrl";
export default {
    components: {
        FeaturedTemplates
    },
    name: "app-templates",
    data() {
        return {
            templates: [],
        };
    },
    methods: {
        getTemplates() {
            const vm = this;
            baseUrl
                .get("/featuredTemplates")
                .then((response) => {
                    if (response.data && response.data.status == '00') {
                        console.log('The templates', response.data);
                        vm.templates = [...response.data.data];
                    }
                })
                .catch((error) => {
                    console.log('There was an error>>>', error);
                });
        },
    },
    mounted() {
        this.getTemplates();
    },
};
</script>
