<template>
  <div class="container" style="padding:50px;line-height: 1.6">

    <div class="card">
      <div class="card-body">
        <h4 class="card-title">
          <h3> About Shortcode Africa </h3>
        </h4>
        <hr>
        Shortcode Africa is a comprehensive USSD cloud solution that allows you to easily build USSD applications,
        accept
        payments, and send SMS messages. With Shortcode Africa, you can have your application live within minutes of
        signing up.
        <br>
        Additionally, our platform allows you to connect to external APIs and databases to enhance the functionality of
        your
        application. Choose from a variety of shortcodes and make your solution live instantly with Shortcode Africa.
        <br> <br>
        <div class="text-info">For customised shortcodes <a href="/contact">Contact Us</a></div>
        <br>
        <br>
        <hr>
        ShortCode Africa is a product of Cache Technology Limited, a leading provider of software development solutions
        and
        other professional IT services. Our team at Cache Technology is made up of experienced and talented
        professionals in the
        fintech industry, and we are dedicated to delivering innovative solutions to our clients. As a licensed and
        bonded
        company, we are committed to the highest level of professionalism and quality.
        <br>
      </div>
    </div>
  </div>
</template>
<style scoped>
p {
  position: inherit !important;
}
</style>