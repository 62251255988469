<template>
  <div class="card">
    <div class="card-header">
      <h4 style="padding: 10px">Connect <span class="font-weight-bold">{{currentShortCode.shortCode}}</span>  To An App</h4>
    </div>
    <div class="card-body">
      <div class="row">
        <div class="col-md-2 col-sm-12"></div>
        <div class="col-md-9 col-sm-12">
          <form class="forms-sample" >
            <div class="form-group">
              <div class="card card-inverse-info">
                <div class="card-body">
                  <div class="card-title font-weight-bold"></div>
                  <div class="card-text">
                    <div class="row">
                      <div class="col-1">
                        <div class="badge badge-pill badge-outline-info">
                          <i class="fas fa-info-circle"></i>
                        </div>
                      </div>
                      <div class="col-11">
                        This page allows your to connect your USSD ShortCodes to a designed menu flow
                        <br>
                            <div class="text-warning mt-2" v-if="myApps.length==0">
                              You have not created any app that you can connect to this ShortCode
                                <router-link to="/create-app" class="btn btn-sm btn-warning text-white font-weight-bold">Create App
                                </router-link>
                            </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="form-group" v-if="currentShortCode.connected">
              <div class="card card-inverse-warning">
                <div class="card-body">
                   <div class="card-text">
                    <div class="row">
                      <div class="col-1">
                        <div class="badge badge-pill badge-outline-info">
                          <i class="fas fa-info-circle"></i>
                        </div>
                      </div>
                      <div class="col-11">
                        This USSD shortcode is currently connected to app with name : <span class="font-weight-bold">{{existingConnection.appName}}</span> .
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="form-group row" v-if="errorMsg.length">
                <div class="col-12">
                  <div class="alert alert-danger alert-dismissible fade show" role="alert">
                    <strong v-for="err in errorMsg">{{ err }}</strong>
                    <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                </div>
            </div>
            <div class="form-group row">
              <label class="col-sm-4 col-form-label">Select App to Connect</label>
              <div class="col-sm-8">
                <select class="form-control"  style="
                    background-color: #2b2e4c;
                    color: #e9e6e6;
                    border: 1px solid #424351;
                  " v-model="payload.appId" >
                  <option value="">..Select App..</option>
                  <option v-for="item in myApps" :value="item.appId">{{ item.appName }}</option>
                </select>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-sm-4 col-form-label">Connect To</label>
              <div class="col-sm-8">
                <div class="form-group mb-0">
                  <div class="form-check">
                    <label class="form-check-label">
                      <input type="radio" class="form-check-input" name="linkedAppEnv" value="test"
                             v-model="payload.linkedAppEnv" checked="checked" />
                      Test Environment
                      <i class="input-helper"></i></label>
                  </div>
                  <div class="form-check form-check-success">
                    <label class="form-check-label">
                      <input type="radio" class="form-check-input" name="linkedAppEnv"
                             v-model="payload.linkedAppEnv" value="production" />
                      Production
                      <i class="input-helper"></i></label>
                  </div>
                </div>
              </div>
            </div>
            <button type="button"  class="btn btn-primary  mt-0 text-white float-right" @click="submit()">
              Connect ShortCode
            </button>
          </form>
        </div>
        <div class="col-md-1 col-sm-12"></div>
      </div>
    </div>

  </div>
</template>
<script>
import api from "@/shared/menuApi";
import { useToast } from "vue-toastification";


export default {
  name: "ConnectAppToShortCode",
  setup() {
     const toast = useToast();
    return { toast };
  },
  data() {
    return {
      errorMsg: [],
      currentShortCode:{},
      payload:{appId:"",linkedAppEnv:"test"},
      existingConnection:{},
      myApps: [],
      };
  },
  methods: {
     getApp() {
      let vm=this;
      api.getAllApps()
              .then((response) => {
                vm.myApps = response.data;
              })
              .catch((error) => {
                console.log("Error", error);
                this.errors = error.data;
              });
    },
    getShortCodeToConnect() {
      let vm = this;
      let uuid = this.$route.params.id
      api.doGet(`/api/getShortCodeByUuid/${uuid}`)
              .then((response) => {
                console.log('The current ShortCode is >>>',response);
                 vm.currentShortCode = response;
                 if(response.connected){
                   vm.existingConnection = response.existingConnection;
                   vm.payload.appId=vm.existingConnection.appId;
                   vm.payload.linkedAppEnv=response.linkedAppEnv;
                 }
              })
              .catch((error) => {
                this.toast.error(error.message);
              });
    },
    submit(){
       this.errorMsg=[];
       if(this.payload.appId===''){
         this.errorMsg.push("Select app to connect");
         return ;
       }
      let payload = Object.assign({},this.payload);
       payload.uuid=this.currentShortCode.uuid;
       console.log('The payload is >>>',payload);
       let vm=this;
      api.doPost(`/api/connectAppToShortCode/`,payload)
              .then((response) => {
                 if(response.status ==="00"){
                   this.toast.success(response.message);
                   setTimeout(function () {
                     vm.$router.push("/my-shortcodes");
                   }, 1000);
                }else{
                   this.toast.warning(response.message);
                 }
              })
              .catch((error) => {
                vm.toast.error(error.message);
              });
    }
  },
  mounted() {
    this.getApp();
    this.getShortCodeToConnect();
  }
};
</script>
<style scoped>
#submitBtn {
  margin-top: 20px;
}

#error {
  margin-top: 40px;
}
</style>
