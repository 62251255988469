<template>
        <div class="appsContainer row" >
            <div class="row">
                <div class="col-md-4 col-sm-6 grid-margin stretch-card"  v-for="item in templates" :key="item.appId" >
                    <div class="card ">
                        <div class="card-header">
                            <span :class="item.flagIcon"></span>
                            <p>{{ item.appName }}</p>
                        </div>
                        <div class="card-body">
                            <p class="app_p"> {{item.description}}</p>
                        </div>
                        <div class="card-footer">
                            <router-link class="btn btn-danger text-white font-weight-bold mr-2"
                                         :to="`/apptemplate/emulator/${item.appId}?returnUrl=${returnUrl}`">
                                <i class="fa-solid fa-play"></i>
                                Test Run
                            </router-link>
                            <router-link class="btn btn-success"
                                         :to="`/app-templates`">
                                <i class="fa-solid fa-clone"></i>
                                Clone
                            </router-link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
</template>



<script>
    import baseUrl from "@/shared/baseUrl";
    export default {
        name: "app-templates",
        data() {
            return {
                templates: [],
                returnUrl: ""
            };
        },
        methods: {
            getTemplates() {
                const vm = this;
                baseUrl
                    .get("/featuredTemplates")
                    .then((response) => {
                        if (response.data && response.data.status == '00') {
                             vm.templates = [...response.data.data];
                        }
                    })
                    .catch((error) => {
                        console.log('There was an error>>>', error);
                    });
            },
        },
        mounted() {
            this.getTemplates();
            this.returnUrl = window.location.pathname;
        },
    };
</script>

<style scoped>

.thingsBuild{
  background-color:#2a3342;
  padding-bottom: 50px;
}
.thingsBuild hr{
  color: #333F51 !important;
  background-color: #333F51 !important;
  margin-bottom: 20px;
}
.card{
  background-color: #fff;
  color: #000;
  margin: 10px;
  width: 480px;
  height: 300px;
  border-radius: 10px;
}
.card-header{
  display: flex;
  align-items: center;
  padding: 20px;
  border: none;
  background-color: #fff;
}
.card-footer{
  border: none;
  background-color: #fff;
}
.thingsBuild h1{
  font-size: 30px;
  color: #fff;
  font-weight: bold;
}
.thingsBuildContent{
  width: 80%;
  margin: 0 auto;
}
/*.thingsBuildContent_p{*/
/*  color: #fff;*/
/*  margin-top: 20px;*/
/*  margin-bottom: 20px;*/
/*}*/
.btn-danger{
  background-color: #FF5B4F;
  border-radius: 20px;
  border: none;
}
.btn-success{
  background-color: #09EB84;
  border-radius: 20px;
  border: none;
}

.card-header p{
  margin-left: 10px;
  font-weight: bold;
  font-size: 20px;
  color:#1a1e23;
}
.app_p{
  margin-bottom: 30px;
  padding: 20px;
  color:#1a1e23;
}
.appsContainer{
  display: flex;
  align-items: center;
}
/*@media (max-width: 767px) {*/
/*  .appsContainer {*/
/*    flex-direction: column;*/
/*    text-align: center;*/
/*    padding: 8px;*/
/*  }*/
/*  .card{*/
/*    width: 100%;*/
/*  }*/
/*  .app_p{*/
/*    margin-bottom: 5px;*/
/*  }*/
/*  .shapes{*/
/*    display: none;*/
/*  }*/
/*}*/


</style>
