<template>
  <div>
    <button type="button" @click="getValue()">Get Value</button>
<!--    <textarea class="form-control" v-model="code"></textarea>-->
<!--    <v-ace-editor-->
<!--      v-model:value="code"-->
<!--      :readonly="false"-->
<!--      :value="code"-->
<!--      @change="getValue"-->
<!--      @init="editorInit"-->
<!--      :lang="language"-->
<!--      theme="monokai"-->
<!--      style="height: 300px"-->
<!--    />-->
  </div>
</template>

<script>
import { VAceEditor } from "vue3-ace-editor";
import "ace-builds/src-noconflict/mode-text";
import "ace-builds/src-noconflict/mode-json";
import "ace-builds/src-noconflict/mode-javascript";
import "ace-builds/src-noconflict/mode-sql";
import "ace-builds/src-noconflict/theme-monokai";

export default {
  components: {
    VAceEditor,
  },
  props: {
    language: {
      type: String,
      default: "javascript",
    },
    code: {
      type: String,
      default: "",
    },
  },
  methods: {
    editorInit() {},
    getValue(event) {
      //    console.log(event);
      //    console.log(this.code);
      //console.log('The value >>',this.content);
      // this.$parent.setValue(this.code);
    },
  },
};
</script>

<style lang="scss" scoped></style>
