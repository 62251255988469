<template>
  <div>
    <ul>
      <div class="content-wrapper">
        <div class="row">
          <div class="col-md-12 grid-margin">
            <div class="row">
              <div class="col-9 mb-0 mb-xl-0">
                <h4 class="font-weight-bold">
                  Application Logs
                  <ToolTip
                    content="Troubleshoot application issues. Logs from you application are displayed in this table. You can filter by log level, action type or menu"
                  />
                </h4>
              </div>
              <div class="col-md-3">
                <button
                  class="btn btn-danger btn-md mr-1 text-white float-right"
                  @click="clearLogs()"
                >
                  <span class="h4 text-white">Clear Logs </span>
                  <i class="fa-solid fa-eraser btn-icon-prepend"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <div class="card">
              <div class="card-body">
                <div class="row"></div>
                <div class="row">
                  <div class="col-12">
                    <form>
                      <div class="row">
                        <label class="col-sm-1 col-form-label"></label>
                        <div class="col-sm-3">Menus</div>
                        <div class="col-sm-3">Action Type</div>
                        <div class="col-sm-3">Log Level</div>
                        <div class="col-sm-2"></div>
                      </div>
                      <div class="form-group row">
                        <label class="col-sm-1 col-form-label"></label>
                        <div class="col-sm-3">
                          <div class="input-group mb-3">
                            <div class="input-group-prepend">
                              <div class="input-group-text">
                                <input
                                  type="checkbox"
                                  v-model="searchFilter.filterByMenu"
                                />
                              </div>
                            </div>
                            <select
                              class="form-control form-control-sm"
                              v-model="searchFilter.menuId"
                              :disabled="searchFilter.filterByMenu == false"
                            >
                              <option value="">..Select Menu..</option>
                              <option
                                v-for="(option, index) in menusList"
                                v-bind:value="option.uniqueId"
                                :key="index"
                              >
                                {{ option.displayText }}
                              </option>
                            </select>
                          </div>
                        </div>
                        <div class="col-sm-3">
                          <div class="input-group mb-3">
                            <div class="input-group-prepend">
                              <div class="input-group-text">
                                <input
                                  type="checkbox"
                                  v-model="searchFilter.filterByPlugin"
                                />
                              </div>
                            </div>
                            <select
                              class="form-control form-control-sm"
                              :disabled="searchFilter.filterByPlugin == false"
                              v-model="searchFilter.plugin"
                            >
                              <option value="">..Select one..</option>
                              <option value="javascript">Javascript</option>
                              <option value="restapi">Rest Api Calls</option>
                              <option value="dbaccess">Database Calls</option>
                              <option value="sendsms">Sms Sending</option>
                              <option value="paymentprompt">
                                Payment Prompt
                              </option>
                            </select>
                          </div>
                        </div>
                        <div class="col-sm-3">
                          <div class="input-group mb-3">
                            <div class="input-group-prepend">
                              <div class="input-group-text">
                                <input
                                  type="checkbox"
                                  v-model="searchFilter.filterByLevel"
                                />
                              </div>
                            </div>
                            <select
                              class="form-control form-control-sm"
                              :disabled="searchFilter.filterByLevel == false"
                              v-model="searchFilter.level"
                            >
                              <option value="">..Select one..</option>
                              <option value="system">System</option>
                              <option value="debug">Debug</option>
                              <option value="info">Info</option>
                              <option value="error">Error</option>
                            </select>
                          </div>
                        </div>
                        <div class="col-sm-2">
                          <button
                            type="button"
                            class="btn btn-success text-white"
                            @click="searchLogs()"
                          >
                            Filter
                            <span
                              class="circle-loader ml-2"
                              style="display: none"
                              id="loader"
                            ></span>
                          </button>
                        </div>
                      </div>
                    </form>
                    <p></p>
                  </div>

                  <div class="col-12" v-show="logdata.length">
                    <table
                      id="searchResultsTable"
                      class="table dt-responsive"
                      cellspacing="0"
                      style="width: 100%"
                    >
                      <thead>
                        <tr>
                          <th>Time</th>
                          <th>Level</th>
                          <th>Plugin:Action:Menu</th>
                          <th>Message</th>
                          <th>Details</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ul>
  </div>
</template>

<script>
import baseUrl from "@/shared/baseUrl";
import { useToast } from "vue-toastification";
import ToolTip from "@/components/tooltipComponent.vue";
import api from "@/shared/menuApi";

export default {
  components: {
    ToolTip,
  },
  data() {
    return {
      appId: this.$route.params.id,
      searchFilter: {
        appId: this.$route.params.id,
        filterByMenu: false,
        filterByLevel: false,
        filterByPlugin: false,
        level: "",
        menuId: "",
        plugin: "",
      },
      logdata: [],
      menusList: [],
    };
  },
  setup() {
    const toast = useToast();
    return { toast };
  },
  async mounted() {
    await this.getMenusLite();
    setTimeout(() => this.searchLogs(), 2000);
  },
  methods: {
    async getMenusLite() {
      this.menusList = await api.getAllMenusLite(this.$route.params.id);
    },
    clearLogs() {
      const vm = this;
      baseUrl.get("/actionlogs/clear/" + this.appId).then((res) => {
        $("#loader").hide();
        if (res.data.status == "00") {
          this.toast.success(res.data.message);
          vm.logdata = [];
          vm.renderDataTable(vm.logdata);
        }
      });
    },
    searchLogs() {
      const vm = this;
      try {
        const payload = Object.assign(this.searchFilter);
        console.log("Search Payload >>", payload);
        $("#loader").show();
        $("#resultsResponse").hide();
        this.searchResults = [];
        baseUrl.post("/actionlogs/searchLogs", payload).then((res) => {
          $("#loader").hide();
          if (res.data.status == "00") {
            console.log("Rendering data table>>", res.data.data);
            vm.logdata = res.data.data;
            vm.renderDataTable(vm.logdata);
          }
        });
      } catch (error) {
        $("#resultsResponse").hide();
        $("#loader").hide();
        this.searchBy = "";
        this.date.startDate = "";
        this.date.endDate = "";
      }
    },
    async renderDataTable(rows) {
      let dataRows = rows;
      console.log("The rows are >>>", rows);
      let table = $("#searchResultsTable").DataTable({
        destroy: true,
        paging: true,
        dom: "Bfrtip",
        buttons: ["copy", "csv"],
        data: dataRows,
        columns: [
          { data: "time" },
          { data: "logLevel" },
          { data: "pluginActionMenu" },
          { data: "message" },

          {
            className: "showRowDetails",
            orderable: false,
            data: null,
            defaultContent: `<button type="button" class="btn btn-primary btn-rounded btn-icon text-white showRowDetails"><i class="fa fa-plus"></i></button>`,
          },
        ],
        responsive: true,
        order: [[1, "asc"]],
      });

      let vm = this;
      $("#searchResultsTable tbody").on(
        "click",
        "td.showRowDetails",
        function () {
          var tr = $(this).closest("tr");
          var row = table.row(tr);
          if (row.child.isShown()) {
            row.child.hide();
            tr.removeClass("shown");
          } else {
            row.child(vm.getDetailsRow(row.data())).show();
            tr.addClass("shown");
          }
        }
      );
    },
    getDetailsRow(data) {
      if (data == null) {
        this.toast.success("No Data");
        return;
      }
      console.log("The row data>>>", data);
      let table = "<pre style='color:white'><code>";
      table += data.data;
      table += "</code></pre>";
      console.log("Returning details row >>>", table);
      return table;
    },
  },
};
</script>

<style lang="css" scoped>
select,
select:focus {
  color: rgb(15, 8, 8);
}
select:disabled {
  color: rgb(171, 169, 169);
}
#searchResultsTable.table td {
  vertical-align: middle;
  line-height: 1;
  white-space: nowrap;
  padding: 0.25rem 0.2375rem !important;
}

.dataTables_wrapper .dataTable .btn {
  padding: 0.1rem 0rem;
  vertical-align: top;
}

.btn.btn-icon {
  width: 38px;
  height: 38px;
  /* padding: 0; */
}
</style>
