<template>
  <div>
    <div class="content-wrapper">
      <div class="row">
        <div class="col-md-12 grid-margin">
          <div class="row">
            <div class="col-8 mb-0 mb-xl-0">
              <h4 class="font-weight-bold">
                Recent Completed Sessions
              </h4>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-body">
              <div class="mt-5">
                <table id="completedSessionTable" class="table dt-responsive " cellspacing="0" style="width:100%">
                  <thead>
                    <tr>
                      <!-- <th></th> -->
                      <th>ID</th>
                      <th>MOBILE</th>
                      <th>NETWORK</th>
                      <th>DATE</th>
                      <th>ACTIONS</th>
                    </tr>
                  </thead>
                  <tbody>

                    <tr>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                    </tr>

                  </tbody>
                </table>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import baseUrl from '@/shared/baseUrl';
export default {
  data() {
    return {
      appId: this.$route.params.id,
      liveDatas: []
    }
  },
  methods: {
    async getLiveData() {
      baseUrl.get(`/dashboard/completedSessions/${this.appId}`).then(res => {
        //   this.liveDatas=res.data
        this.renderDataTable(res.data);
        //   console.log(res.data)
      }).catch(err => {
        console.log(err)
      });
    },
    async renderDataTable(rows) {
      let dataRows = rows;
      let table = $('#completedSessionTable').DataTable({
        "destroy": true,
        "paging": true,
        data: dataRows,
        "columns": [
          { "data": "id" },
          { "data": "mobile" },
          { "data": "network" },
          { "data": "createdAt" },
          {
            "className": 'showRowDetails',
            "orderable": false,
            "data": null,
            "defaultContent": `<button type="button" class="btn btn-primary btn-rounded btn-icon text-white showRowDetails">
                                       <strong>+</strong> 
                               </button>`
          },
        ],
        'responsive': true,
        'order': [[1, 'asc']],
      });
      let vm = this;
      $('#completedSessionTable tbody').on('click', 'td.showRowDetails', function () {
        var tr = $(this).closest('tr');
        var row = table.row(tr);
        if (row.child.isShown()) {
          row.child.hide();
          tr.removeClass('shown');
        }
        else {
          row.child(vm.getDetailsRow(row.data())).show();
          tr.addClass('shown');
        }
      });
    },
    getDetailsRow(data) {
      let inputKeys = Object.keys(data.inputs);
      let table = '<table style="border:none">'
      table += '<thead><tr>'
      table += ' <th scope="col">Input Field</th><th scope="col">Data</th>'
      table += '</tr> <tbody>'
      inputKeys.forEach(function (inputKey) {
        table += ' <tr><th scope="row">' + inputKey + '</th><td>' + data.inputs[inputKey] + '</td></tr>'
      })
      table += '</tbody></table>'
      return table;
    }
  },
  async mounted() {
    await this.getLiveData()
  },
  async unmounted(){
    $("#completedSessionTable").DataTable().clear().destroy();
  }
}
</script>

<style >
/* .table td{
    border:none !important;
} */
</style>