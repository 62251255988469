<template>
  <div class="emulaterHome">
    <div>
      <DialPage :homeUrl="`/app/design/${this.$route.params.appId}`" @numberDialed="numberDialed" />
      <MenuDisplay ref="menuDisplayModal" />
      <Logs :isModalShow="true"  />
      <Debugger/>
    </div>
  </div>
</template>

<script type="text/javascript">
import DialPage from "@/components/emulatorDialComponent.vue";
import MenuDisplay from "@/components/emulatorModalComponent.vue";
import Logs from "@/components/emulatorLogs.vue";
import Debugger from "@/components/emulatorDebugger.vue";
export default {
  name: "StarterPage",
  data() {
    return {
      selectedHero: undefined,
      message: "",
      capeMessage: "",
      isModalShow: true,
      LogsResponse: [],
    };
  },
  components: {
    DialPage,
    MenuDisplay,
    Logs,
    Debugger,
  },
  methods: {
    numberDialed(data) {
      var child = this.$refs.menuDisplayModal;
      child.showModal(data);
    },
  }
};
</script>

<style scoped>
.emulaterHome {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 100vh;
  width: 100vw;
  background-color: #f5f5f5;
}
</style>
