<template>
  <div id="nav">
   <router-view/>
  </div>

</template>
<style>
 *{
   font-family: 'Open Sans', sans-serif !important;
 }
</style>

