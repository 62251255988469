<template>
  <div class="container">
    <div class="row">
      <div class="col-12">
        <div class="card" style="margin-top:50px">
          <div class="card-body">
            <div class="container text-center pt-5">
              <h4 class="mb-3 mt-5">Select Pricing Option</h4>
              <p class="w-75 mx-auto mb-5">Choose a plan that suits you the best.</p>
              <div class="row pricing-table">
                <div class="col-md-4 col-xl-4 grid-margin stretch-card pricing-card">
                  <div class="card border-primary border pricing-card-body">
                    <div class="text-center pricing-card-head">
                      <h3>Basic Plan</h3>
                      <h2 class="font-weight-normal mb-4">$10/Month</h2>
                    </div>
                    <ul class="list-unstyled plan-features">
                      <li>2 Ussd Apps</li>
                      <li>50 Screen Menus</li>
                      <li>10,000 Requests Monthly</li>
                      <li>Api Calls support</li>
                      <li>Bulk Sms (1000 free sms)</li>
                      <li>Connect Any external shortcode</li>
                    </ul>
                    <div class="wrapper">
                      <router-link to="/login" class="btn btn-outline-primary btn-block">Start Now</router-link>
                    </div>
                  </div>
                </div>
                <div class="col-md-4 col-xl-4 grid-margin stretch-card pricing-card">
                  <div class="card border border-success pricing-card-body">
                    <div class="text-center pricing-card-head">
                      <h3 class="text-success">Standard Plan</h3>
                      <h2 class="font-weight-normal mb-4">$20/Month</h2>
                    </div>
                    <ul class="list-unstyled plan-features">
                      <li>10 Ussd Apps</li>
                      <li>Unlimited Menus</li>
                      <li>25,000 Requests Monthly</li>
                      <li>Api Calls support</li>
                      <li>Bulk Sms (2000 sms monthly)</li>
                      <li>Connect Any external shortcode</li>
                      <li>Application Support and development</li>
                      <li>5 digit ShortCode Extension</li>
                    </ul>
                    <div class="wrapper">
                      <router-link to="/login" class="btn btn-outline-primary btn-block">Start Now</router-link>
                    </div>
                  </div>
                </div>
                <div class="col-md-4 col-xl-4 grid-margin stretch-card pricing-card">
                  <div class="card border border-primary pricing-card-body">
                    <div class="text-center pricing-card-head">
                      <h3>Premium</h3>
                      <h2 class="font-weight-normal mb-4">$30/Month</h2>
                    </div>
                    <ul class="list-unstyled plan-features">
                      <li>Unlimited Menus</li>
                      <li>100,000 Requests Monthly</li>
                      <li>Api Calls support</li>
                      <li>Bulk Sms (3000 sms monthly)</li>
                      <li>Connect Any external shortcode</li>
                      <li>3 digit ShortCode Extension</li>
                      <li>Application Support and development</li>
                     </ul>
                    <div class="wrapper">
                      <router-link to="/login" class="btn btn-outline-primary btn-block">Start Now</router-link>
                    </div>
                  </div>
                </div>
                <div class="col-md-4 col-xl-4 grid-margin stretch-card pricing-card">
                  <div class="card border border-primary pricing-card-body">
                    <div class="text-center pricing-card-head">
                      <h3>Enterprise Deploment</h3>
                      <h2 class="font-weight-normal mb-4">Request for Quote</h2>
                    </div>
                    <ul class="list-unstyled plan-features">
                      <li>Unlimited Menus</li>
                      <li>100,000 Requests Monthly</li>
                      <li>Api Calls support</li>
                      <li>Bulk Sms (3000 sms monthly)</li>
                      <li>Connect Any external shortcode</li>
                      <li>3 digit ShortCode Extension</li>
                      <li>Application Support and development</li>
                    </ul>
                    <div class="wrapper">
                      <router-link to="/contact" class="btn btn-outline-primary btn-block">Contact Us</router-link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>