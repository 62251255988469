<template>
  <div class="row">
    <div class="col-md-12">
      <div>
        <div>
          <ul class="nav nav-tabs menu-form" role="tablist">
            <li class="nav-item">
              <a class="nav-link active menu-view-tab" id="home-tab" data-bs-toggle="tab" :href="`#menuTab`" role="tab"
                aria-controls="home-1" aria-selected="true">Menu</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" id="profile-tab" data-bs-toggle="tab" :href="`#navigationTab`" role="tab"
                aria-controls="profile-1" aria-selected="false">Navigation</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" id="contact-tab" data-bs-toggle="tab" :href="`#actionTab`" role="tab"
                aria-controls="contact-1" aria-selected="false">Actions</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" id="validation-tab" data-bs-toggle="tab" :href="`#validationTab`" role="tab"
                aria-controls="contact-1" aria-selected="false">Validation</a>
            </li>
          </ul>
          <div class="tab-content menu-form">
            <div class="tab-pane fade show active" :id="`menuTab`" role="tabpanel" aria-labelledby="home-tab">
              <PopOver />
              <div class="form-group">
                <textarea type="text" name="" ref="menuTextArea" cols="10" rows="12" style="resize: none"
                  v-model="menu.menuText" class="form-control" @change="updateShortText()" />
              </div>
              <div class="row">
                <div class="col-5">
                  <div class="row">
                    <div class="col-7">
                     <input type="text" class="form-control form-control-sm pr-0 mr-0" placeholder="Menu Idenitfier"
                        v-model="menu.userDefinedName" />
                    </div>
                    <div class="col-5 align-middle">
                      <ToolTip class="pt-1 pl-0"
                        content="Give the menu a unique name to identify it anywhere. No spaces allowed"
                        placement="bottom" />
                    </div>
                  </div>
                </div>
                <div class="col-1"></div>
                <div class="col-2">
                  <div class="form-check form-check-flat form-check-primary">
                    <label class="form-check-label">
                      <input type="checkbox" class="form-check-input" v-model="menu.terminate" />
                      Terminate
                      <i class="input-helper"></i></label>
                  </div>
                </div>
                <div class="col-4">
                  <div class="row">
                    <div class="col-6">
                      <div class="form-check form-check-flat form-check-primary">
                        <label class="form-check-label">
                          <input type="checkbox" class="form-check-input" v-model="menu.allowGoBack" />
                          Back Menu
                          <i class="input-helper"></i></label>
                      </div>
                    </div>
                    <div class="col-6">
                      <input type="text" placeholder="Indicator" v-show="menu.allowGoBack == true"
                        class="form-control form-control-sm" v-model="menu.goBackInputIndicator" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- Navigation start here -->
            <div class="tab-pane fade" :id="`navigationTab`" role="tabpanel" aria-labelledby="profile-tab">
              <div>
                <div class="row">
                  <Navigation :id="`navigation${index}`" :switchOperations="menu.switchOperations" v-bind:index="index"
                    ref="navComponent" />
                </div>
              </div>
            </div>
            <!-- Ends here -->
            <!-- Action started here -->
            <div class="tab-pane fade" :id="`actionTab`" role="tabpanel" aria-labelledby="contact-tab">
              <div class="row">
                <div v-if="initialized">
                  <Action id="actionComponent" :data="menu.actions" ref="menuActions" />
                </div>
              </div>
            </div>
            <div class="tab-pane fade" :id="`validationTab`" role="tabpanel" aria-labelledby="validation-tab">
              <div>
                <Validation :id="`validation${index}`" :inputValidations="menu.inputValidations" v-bind:index="index"
                  ref="validations" />
              </div>
            </div>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-sm-12 text-right">
            <button class="btn btn-outline-warning btn-lg mr-5" tooltip="Cancel Edit" @click="cancelEdit()">
              Cancel
            </button>
            <button class="btn btn-outline-success btn-lg updateBtn" tooltip="Save" @click="update()">
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import api from "@/shared/menuApi";
import { useToast } from "vue-toastification";
import Navigation from "@/components/switchOperationsComponent.vue";
import ToolTip from "@/components/tooltipComponent.vue";
import Action from "@/components/actionComponent.vue";
import Validation from "@/components/validationComponent.vue";
import PopOver from "@/components/popoverComponent";
export default {
  components: {
    Navigation,
    Action,
    Validation,
    ToolTip,
    PopOver,
  },
  setup() {
    const toast = useToast();
    return { toast };
  },
  data() {
    const toast = useToast();
    return {
      appId: this.$route.params.id,
      successMessage: "",
      return: toast,
      errorMessage: "",
      terminate: false,
      index: 0,
      initialized: false,
      menu: {
        editMode: false,
        index: 0,
        uniqueId: "",
        menuText: "",
        userDefinedName: "",
        allowGoBack: false,
        goBackInputIndicator: "",
        isFirst: false,
        terminate: false,
        inputHolder: "",
        headerText: "",
        footerText: "",
        parentMenuId: "",
        switchOperations: [],
        actions: [],
        inputValidations: [],
      },
    };
  },
  computed: {
    menuData() {
      return this.$store.getters.getCurrentEditingMenu;
    },
  },
  methods: {
    async remove() {
      let deletedFromServer = false;
      if (this.menu.uniqueId !== "") {
        let response = await api.deleteMenuByUniqueId(this.menu.uniqueId);
        if (response.status == "00") {
          deletedFromServer = true; //if deletedFromServer save menu ordering
          let eventData = {
            index: this.index,
            deletedFromServer: deletedFromServer,
          };
          this.$parent.deleteMenuItem(eventData);
          this.toast.success(response.message);
        } else {
          this.toast.error(response.message);
        }
      } else {
        let eventData = {
          index: this.index,
          deletedFromServer: deletedFromServer,
        };
        this.$parent.deleteMenuItem(eventData);
      }
    },
    truncateWithElipsis(str, n) {
      if (str.length > n) {
        return str.substring(0, n) + "...";
      } else {
        return str;
      }
    },
    buildPayload() {
      const payload = {
        appId: this.appId,
        isFirst: this.index == 0,
        displayText: this.menu.menuText,
        headerText: this.menu.headerText,
        footerText: this.menu.footerText,
        userDefinedName: this.menu.userDefinedName,
        inputHolder: this.menu.inputHolder,
        allowGoBack: this.menu.allowGoBack,
        terminate: this.menu.terminate,
        goBackInputIndicator: this.menu.goBackInputIndicator,
      };
      payload.parentMenuId = this.menu.parentMenuId;
      if (this.menu.editMode) {
        payload.uniqueId = this.menu.uniqueId;
      }
      payload.switchOperations = this.$refs.navComponent.getSwitchOperations();
      payload.actions = this.$refs.menuActions.getActions();
      payload.inputValidations = this.$refs.validations.getInputValidations();
      console.log("Payload for saving menu >>", payload);
      return payload;
    },
    updateShortText() {
      this.menu.shortText = this.truncateWithElipsis(this.menu.menuText, 95);
    },
    pasteText(eventData) {
      var textArea = this.$refs.menuTextArea;
      if (this.menu.menuText == null || this.menu.menuText == "") {
        this.menu.menuText = eventData.pasteText;
      }
      var startPos = textArea.selectionStart,
        endPos = textArea.selectionEnd,
        tmpStr = textArea.value;
      this.menu.menuText =
        tmpStr.substring(0, startPos) +
        " {{" +
        eventData.pasteText +
        "}}" +
        tmpStr.substring(endPos, tmpStr.length);
    },
    cancelEdit() {
      $("#formModal").toggle("slow");
    },
    async update() {
      let response;
      const payload = this.buildPayload();
      if (this.menu.editMode) {
        response = await api.saveMenu(payload, true);
      } else {
        response = await api.saveMenu(payload, false);
        if (response.status == "00") {
          this.menu.uniqueId = response.uniqueId;
        }
      }

      if (response.status == "00") {
        this.$parent.toast.success(response.message);
        $("#formModal").toggle("hide");
        const commitMenu = JSON.parse(JSON.stringify(this.menu));
        this.$store.commit("setCurrentEditingMenu", commitMenu);
        this.$parent.menusUpdated();
      } else {
        this.$parent.toast.danger(response.message);
      }
    },
    async menusUpdated() {
      const menusLite = await api.getAllMenusLite(this.$route.params.id);
      this.$store.commit("updateMenusLite", menusLite);
      //If menu is inserted at a position we have to save order
      let vm = this;
      setTimeout(function () {
        vm.saveOrderSilent();
      }, 1000);
    },

    initialize() {
      const vm = this;
      this.initialized = false;
      const menuData = this.menuData;
      if (menuData.editeMode == true) {
        this.menu.editMode = true;
        this.menu.index = menuData.index;
        console.log("The display text >>");
        this.menu.menuText = menuData.menuText;
        this.menu.terminate = menuData.terminate;
        this.menu.shortText = menuData.shortText;
        this.menu.userDefinedName = menuData.userDefinedName;
        this.menu.inputHolder = menuData.inputHolder;
        this.menu.allowGoBack = menuData.allowGoBack;
        this.menu.goBackInputIndicator = menuData.goBackInputIndicator;
        this.menu.parentMenuId = menuData.parentMenuId;
        this.menu.actions = menuData.actions;
        this.menu.inputValidations = menuData.inputValidations;
        this.menu.switchOperations = menuData.switchOperations;
        this.menu.uniqueId = menuData.uniqueId;
      } else {
        this.menu.editMode = false;
        this.menu.index = menuData.index;
        this.menu.menuText = "";
        this.menu.uniqueId = "";
        this.menu.isFirst = this.index == 0 ? true : false;
        this.menu.userDefinedName = "";
        this.menu.inputHolder = "";
        this.menu.allowGoBack = true;
        this.menu.goBackInputIndicator = "0";
        this.menu.actions = [];
        this.menu.inputValidations = [];
        this.menu.switchOperations = [];
        this.menu.parentMenuId = menuData.parentMenuId;
      }
      this.$refs.navComponent.reMount();
     console.log("The switch operations>>", JSON.stringify(this.menu.switchOperations));
      setTimeout(function () {
        vm.initialized = true;
      }, 1000);
    },
  },
};
</script>

<style scoped>
.closeBtn {
  margin-top: 10px;
  width: 200px;
  /* margin-left: 88%; */
}

/* .actions {
  display: flex;
  margin-left: 80%;
} */

.button {
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: 1;
  padding: 60px;
}

.card .card-body {
  border-radius: 10px !important;
}

select {
  background-color: #2b2e4c;
  color: #fff;
  border: 1px solid #424351;
}

#right {
  float: right;
  z-index: 9999;
}

article {
  display: none;
}

article.on {
  display: block;
}

textarea {
  color: #fff;
}

/* make form input color white */
input,
select,
textarea {
  color: #fff;
}

/* 
#switchButton{
    margin-left: 85%;
    margin-top: 10px;
    margin-right: 20px;
}
#menuName{
    width: 200px;
    margin-left: 10px;
    margin-top: 10px;
} */
</style>
