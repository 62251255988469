<template>
  <div class="container" style="padding: 70px">
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="card-title">
              <h3>FAQ</h3>
            </div>
            <hr />
            <div class="faq-section">
              <div id="accordion-1" class="accordion">
                <div class="card">
                  <div class="card-header">
                    <h5 class="mb-0">
                      <a
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseFaq5"
                        aria-expanded="false"
                        aria-controls="collapseThree"
                      >
                        How long does it take to get my ussd app ready after
                        design.
                      </a>
                    </h5>
                  </div>
                  <div
                    id="collapseFaq5"
                    class="collapse"
                    aria-labelledby="headingThree"
                    data-bs-parent="#accordion-1"
                  >
                    <div class="card-body">
                      <span class="mb-0">
                        Your app can be ready within minutes after clicking the
                        deploy button.
                      </span>
                    </div>
                  </div>
                </div>
                <div class="card">
                  <div class="card-header">
                    <h5 class="mb-0">
                      <a
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseFaq2"
                        aria-expanded="false"
                        aria-controls="collapseThree"
                      >
                        What if I have a complicated menu structure
                      </a>
                    </h5>
                  </div>
                  <div
                    id="collapseFaq2"
                    class="collapse"
                    aria-labelledby="headingThree"
                    data-bs-parent="#accordion-1"
                  >
                    <div class="card-body">
                      <span class="mb-0">
                        Our platform can handle menu designs of any level of
                        complexity. If you cannot figure something out you can
                        call our standby engineers to help you with you designs
                        at no charge.
                      </span>
                    </div>
                  </div>
                </div>
                <div class="card">
                  <div class="card-header">
                    <h5 class="mb-0">
                      <a
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseFaq3"
                        aria-expanded="false"
                        aria-controls="collapseThree"
                      >
                        I want to host my app on my own platform
                      </a>
                    </h5>
                  </div>
                  <div
                    id="collapseFaq3"
                    class="collapse"
                    aria-labelledby="headingThree"
                    data-bs-parent="#accordion-1"
                  >
                    <div class="card-body">
                      <span class="mb-0">
                        You can send us your contact information on our
                        <router-link to="/contact"> Contact Us </router-link>
                        page and we will revert with a quote.
                      </span>
                    </div>
                  </div>
                </div>
                <div class="card">
                  <div class="card-header">
                    <h5 class="mb-0">
                      <a
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseFaq4"
                        aria-expanded="false"
                        aria-controls="collapseTwo"
                      >
                        Which countries can I deploy my app to
                      </a>
                    </h5>
                  </div>
                  <div
                    id="collapseFaq4"
                    class="collapse"
                    aria-labelledby="headingTwo"
                    data-bs-parent="#accordion-1"
                  >
                    <div class="card-body">
                      <span class="mb-0">
                        At the moment you can deploy your apps in Ghana,
                        Nigeria, Kenya, Eswatini and Burundi
                      </span>
                    </div>
                  </div>
                </div>

                <div class="card">
                  <div class="card-header">
                    <h5 class="mb-0">
                      <a
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseFaq4"
                        aria-expanded="false"
                        aria-controls="collapseTwo"
                      >
                        Can I use my own payment provider.
                      </a>
                    </h5>
                  </div>
                  <div
                    id="collapseFaq4"
                    class="collapse"
                    aria-labelledby="headingTwo"
                    data-bs-parent="#accordion-1"
                  >
                    <div class="card-body">
                      <span class="mb-0">
                        We advice against it but Yes you can call a rest api
                        with your app to handle payments on your own.
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
p {
  position: inherit !important;
}
</style>
