<template>
    <div>
        <div class="row">
            <div class="col-md-4 col-sm-6 grid-margin stretch-card" v-for="item in list" :key="item.appId">
                <div class="card">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-sm-1">
                                <span :class="item.flagIcon"></span>
                            </div>
                            <div class="col-sm-10">
                                <h3 style="font-size:1.1em" class="card-title font-weight-bold">{{ item.appName }}</h3>
                            </div>
                        </div>
                        <p class="card-description">
                            {{ item.description }}
                        </p>
                        <div class="template-demo">
                            <router-link class="btn btn-warning btn-rounded btn-fw"
                                :to="`/apptemplate/emulator/${item.appId}?returnUrl=${returnUrl}`">
                                <i class="fa-solid fa-play"></i>
                                Test Run
                            </router-link>
                            <button @click="cloneAppClicked(item)" class="btn btn-success btn-rounded btn-fw"
                                data-bs-toggle="tooltip" data-toggle="tooltip" title="Clone App">
                                <i class="fa-solid fa-clone"></i> Clone
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div>
            <div class="modal fade" id="duplicateAppModal" tabindex="-1" role="dialog" aria-labelledby="ModalLabel"
                aria-hidden="true">
                <div class="modal-dialog" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="ModalLabel">
                                Clone App : {{ cloneAppData.appName }}
                            </h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            <form>
                                <div class="form-group">
                                    <label  class="col-form-label">App Name:</label>
                                    <input type="text" class="form-control" v-model="cloneAppData.appName" />
                                </div>
                                <div class="form-group">
                                    <label  class="col-form-label">Description:
                                    </label>
                                    <input type="text" class="form-control" v-model="cloneAppData.description" />
                                </div>
                                <div class="form-group">
                                    <label  class="col-form-label">Short Code:</label>
                                    <input type="text" class="form-control" v-model="cloneAppData.shortCode" />
                                </div>
                            </form>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-success text-white" @click="cloneAppSubmitted()">
                                Clone App
                            </button>
                            <button type="button" class="btn btn-light" @click="closeModal()">
                                Cancel
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>


<script>

import api from "@/shared/menuApi";
import { useToast } from "vue-toastification";

export default {
    name: "templatesComponent",
    components: {
    },
    props: {
        list: Array
    },
    setup() {
        const toast = useToast();
        return { toast };
    },
    data() {
        return {
            cloneAppData: {},
            returnUrl: ""

        };
    },
    methods: {
        closeModal() {
            jQuery("#duplicateAppModal").modal("hide");
        },
        cloneAppClicked(data) {
            this.cloneAppData = {};
            this.cloneAppData.appId = data.appId;
            this.cloneAppData.appName = data.appName;
            this.cloneAppData.description = data.description;
            this.cloneAppData.useTemplateTag = data.useTemplateTag;
            this.cloneAppData.shortCode = data.shortCode;
            jQuery("#duplicateAppModal").modal("show");
        },
        cloneAppSubmitted() {
            let payload = Object.assign({}, this.cloneAppData);
            var vm = this;
            api
                .cloneApp(payload)
                .then((response) => {
                    console.log('Clone App Response >>>', response);
                    vm.cloneAppData = {};
                    if (response.status == "00") {
                        this.toast.success(response.message);
                    } else {
                        this.toast.error(response.message);
                    }
                    jQuery("#duplicateAppModal").modal("hide");
                    setTimeout(function () {
                        vm.$router.push("/app-list");
                    }, 1500);
                })
                .catch((error) => {
                    this.toast.error(error.message);
                });
        }
    },
    mounted() {
        this.returnUrl = window.location.pathname;
    },
};
</script>

