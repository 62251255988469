<template>
  <div>
    <div class="row">
      <div class="col-md-12 grid-margin">
        <div class="row">
          <div class="col-8 mb-0 mb-xl-0">
            <h4 class="font-weight-bold">
              Menu Design View :
              <span>{{ appData.appName }}</span>
            </h4>
          </div>
          <div class="col-4">
            <button type="button" class="btn btn-danger btn-icon-text text-white float-right" @click="deployChanges()">
              <span class="h4 text-white">Deploy </span>
              <i class="fa fa-angle-double-up btn-icon-prepend"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12" id="formModal">
        <div class="card rounded1 border mb-2" style="border-radius: 20px 0 0 20px; !important">
          <div class="card-body p-3">
            <div class="media">
              <div class="media-body">
                <div>
                  <div class="card card-body">
                    <MenuDesignForm ref="menuForm" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-12 mt-2">
        <div class="card">
          <div class="card-body">
            <div class="row mb-2" v-show="menuItems.length">
              <div class="col-10">
                <form class="forms-sample form-horizontal">
                  <input placeholder="Search Filter" type="text" v-model="filter"
                    class="form-control form-control-lg" />
                </form>
              </div>
              <div class="col-2">
                <button class="btn btn-outline-primary text-white mr-1" @click="saveOrder()">
                  Save order
                </button>
              </div>
            </div>
            <div class="row addnewMenu">
              <div class="col-md-12" v-show="menuItems.length == 0">
                <div>
                  <button type="button" class="btnFirstMenu btn btn-primary btn-sm" style="color: #fff"
                    @click="addMenu(0)">
                    <b> + </b>
                  </button>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12" v-if="renderUpdate">
                <div class="py-2">
                  <draggable v-model="menuItemsFiltered" group="menu" @start="drag = true" @end="drag = false"
                    item-key="uniqueId" handle=".handle">
                    <template #item="{ element, index }">
                      <div>
                        <div>
                          <input type="hidden" v-model="element.uniqueId" class="drag-menu-uniqueId" />
                          <div class="card rounded border mb-2">
                            <div class="card-body p-3">
                              <div class="media">
                                <i class="fa-solid handle fa-up-down-left-right icon-sm align-self-center me-3 fa fa-arrows text-primary header"
                                  style="cursor: move"></i>
                                <div class="media-body">
                                  <div class="btn btn-primary" style="
                                      display: flex;
                                      justify-content: space-between;
                                      width: 100%;
                                      background-color: #27293d;
                                      border: none;
                                      color: #fff;
                                    ">
                                    <div class="bold">
                                      <span class="label h5">{{
                                          element.userDefinedName
                                      }}</span>
                                      : <span> {{ element.shortText }}</span>
                                    </div>
                                    <span>
                                      <button @click="editMenu(index, element)" type="button"
                                        class="btn btn-primary btn-sm mr-1 text-white">
                                        <i class="fa fa-pencil" aria-hidden="true"></i>
                                      </button>
                                      <button @click="deletemenu(element.uniqueId)" type="button"
                                        class="btn btn-danger btn-sm text-white">
                                        <i class="fa fa-trash" aria-hidden="true"></i>
                                      </button>
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div style="
                              display: flex;
                              justify-content: center;
                              align-items: center;
                            ">
                            <div style="margin-right: 10px">
                              <button type="button" class="btn btn-primary btn-sm" style="
                                  color: #fff;
                                  margin-top: 20px;
                                  margin-bottom: 20px;
                                " @click="addMenu(index, menu)">
                                <b> + </b>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </template>
                  </draggable>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { useToast } from "vue-toastification";
import api from "@/shared/menuApi";
import ToolTip from "@/components/tooltipComponent.vue";
import MenuDesignForm from "@/components/menuDesignForm";
import draggable from "vuedraggable";

export default {
  components: {
    MenuDesignForm,
    ToolTip,
    draggable,
  },
  setup() {
    const toast = useToast();
    return { toast };
  },
  data() {
    return {
      appData: {},
      drag: false,
      menuItems: [],
      menuItemsFiltered: [],
      filter: "",
      renderUpdate: true,
      editMode: false,
      showModal: false,
      steps: [
        {
          target: "#v-step-0",
          header: {
            title: "Get Started",
          },
          content: `Click the plus button to add first menu!`,
        },
      ],
    };
  },
  computed: {
    dropDownMenus() {
      return this.$store.getters.getMenusLite;
    },
  },
  watch: {
    filter(newValue, oldValue) {
      this.filterMenusBySearch(newValue);
    },
  },
  methods: {
    filterMenusBySearch(searchFilter) {
      console.log("The filtere change trigered");
      if (searchFilter.trim().length < 3) {
        this.menuItemsFiltered = this.menuItems;
      }
      this.menuItemsFiltered = this.menuItems.filter((menu) => {
        const filter = searchFilter.toUpperCase();
        const hasIdMatch = menu.userDefinedName.toUpperCase().includes(filter);
        const hasTextMatch = menu.displayText.toUpperCase().includes(filter);
        return hasIdMatch || hasTextMatch;
      });
    },
    addMenu(index, menu) {
      $("#formModal").toggle("slow");
      let parentMenuId = "";
      if (menu) {
        parentMenuId = menu.uniqueId;
      }
      const vm = this;
      const menuData = {
        parentMenuId: parentMenuId,
        editeMode: false,
        index: index,
      };
      this.$store.commit("setCurrentEditingMenu", menuData);
      vm.$refs.menuForm.initialize();
    },
    editMenu(index, menu) {
      const vm = this;
      if (menu) {
        const menuData = Object.assign({ editeMode: true, index: index }, menu);
        this.$store.commit("setCurrentEditingMenu", menuData);
        vm.$refs.menuForm.initialize();
        $("#formModal").toggle("slow");
        this.showMenuTab();
      }
    },
    showMenuTab(){
      $(".menu-form .nav-item .nav-link").removeClass("active");
      $(".menu-view-tab").addClass("active");
      $(".menu-form .tab-pane").removeClass("active");
      $(".menu-form .tab-pane").removeClass("show");
      $("#menuTab").addClass("active");
      $("#menuTab").addClass("show");
    },
    async menusUpdated() {
      const menuData = this.$store.getters.getCurrentEditingMenu;
      if (menuData.editMode) {
        this.menuItems.splice(menuData.index, 1, menuData);
      } else {
        let index = menuData.index;
        if (index == this.menuItems.length - 1) {
          this.menuItems.push(menuData);
        } else {
          index += 1;
          this.menuItems.splice(index, 0, menuData);
        }
      }
      console.log('The menus updated>>>', this.menuItems);
      const menusLite = await api.getAllMenusLite(this.$route.params.id);
      this.$store.commit("updateMenusLite", menusLite);
      //If menu is inserted at a position we have to save order
      let vm = this;
      vm.menuItemsFiltered = vm.menuItems;
      setTimeout(function () {
        vm.saveOrderSilent();
      }, 1000);
    },
    async deployChanges() {
      let response = await api.deployChanges(this.appData.appId);
      if (response.status == "00") {
        this.toast.success(response.message);
      } else {
        this.toast.success("Deployment in progress");
      }
    },
    async saveOrder() {
      const menuIdElements = document.querySelectorAll(".drag-menu-uniqueId");
      const menuOrder = [];
      const payload = { appId: this.$route.params.id };
      menuIdElements.forEach((element) => {
        let mId = element.value;
        if (mId != null && mId.length > 0) {
          menuOrder.push(element.value);
        }
      });
      payload.menuIds = menuOrder;
      let response = await api.saveMenuOrder(payload);
      if (response.status == "00") {
        this.toast.success(response.message);
        this.reloadMenus();
      } else {
        this.toast.error(response.message);
      }
    },
    async saveOrderSilent() {
      if (this.filter.trim().length > 0) {
        return;
      }
      const menuIdElements = document.querySelectorAll(".drag-menu-uniqueId");
      const menuOrder = [];
      const payload = { appId: this.$route.params.id };
      menuIdElements.forEach((element) => {
        let mId = element.value;
        if (mId != null && mId.length > 0) {
          menuOrder.push(element.value);
        }
      });
      payload.menuIds = menuOrder;
      await api.saveMenuOrder(payload);
    },
    async reloadMenus() {
      this.renderUpdate = false;
      const menus = await api.getAllMenus(this.$route.params.id);
      let vm = this;
      vm.menuItems = [];
      menus.forEach(function (menu) {
        vm.menuItems.push(menu);
      });
      this.renderUpdate = true;
    },
    async getAppInfo() {
      let response = await api.getAppById(this.$route.params.id);
      if (response.status == "00") {
        this.appData = response.data;
      }
    },
    async deletemenu(uniqueId) {
      let deletedFromServer = false;
      if (uniqueId !== "") {
        let response = await api.deleteMenuByUniqueId(uniqueId);
        if (response.status == "00") {
          deletedFromServer = true;
          let eventData = {
            deletedFromServer: deletedFromServer,
            uniqueId: uniqueId
          };
          const index = this.menuItems.findIndex(m => m.uniqueId === eventData.uniqueId);
          this.menuItems.splice(index, 1);
          if (this.filter.trim().length > 0) {
            this.filterMenusBySearch(this.filter);
          }
          const menusLite = await api.getAllMenusLite(this.$route.params.id);
          this.$store.commit("updateMenusLite", menusLite);
          this.toast.warning(response.message);
        } else {
          this.toast.error(response.message);
        }
      }
    },
    truncateWithElipsis(str, n) {
      if (str.length > n) {
        return str.substring(0, n) + "...";
      } else {
        return str;
      }
    },
    async toggleDiv() {
      //toggle from right to left
      $("#formModal").toggle("slow");
    },
  },
  async mounted() {
    // this.$tours["myTour"].start();
    const menusLite = await api.getAllMenusLite(this.$route.params.id);
    const menus = await api.getAllMenus(this.$route.params.id);
    this.$store.commit("updateMenusLite", menusLite);
    this.menuItems = [...menus];
    this.menuItemsFiltered = this.menuItems;
    await this.getAppInfo();
  },
};
</script>
<style scoped>
.actionsButtons {
  display: flex;
  margin-bottom: 5px;
}

#formModal {
  display: none;
  width: 75%;
  height: 100%;
  min-height: 100% !important;
  right: 0px;
  top: 7.5%;
  bottom: 0px;
  position: fixed;
  z-index: 99999;
  opacity: 1;
  background-color: rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  overflow-y: scroll;
  overflow-x: hidden;
}

.actbtn {
  display: flex;
}

.addnewMenu {
  margin-left: 50%;
}
</style>
