<template>
  <div>
    <div class="jumbotron">
       <div class="shapes">
        <div class="circle1"></div>
        <div class="circle2"></div>
        <div class="circle3"></div>
        <div class="circle4"></div>
       </div>
        <div class="jumbotron-content">
          <div class="jumbotron-companyinfo">
            <h1 class="jumbotron-heading">
              Build your USSD app now. <br>
              Collect payments instantly.
            </h1>
            <p class="jumbotron-text">
              With simplified low-code platform, you don’t have to know how to code. Our platforms designed to be easier for non-coders to get started instantly. You can use our payments and sms api to build powerful solutions to bring your ideas to life.
            </p>
            <div class="actionBtn">
              <router-link to="/register"  class="btn-primaryhome">Get Started</router-link>
            </div>
          </div>
          <div class="jumbotron-companyImage">
            <div class="card serviceCard">
              <div class="card-body text-center">
                <img src="../../public/image/Group3.png" class="serviceImage">
                <h1>USSD</h1>
                <p>Build real-time USSD applications with our low code engine be live in a few minutes  </p>
              </div>
            </div>
            <div class="bottomService">
              <div class="card smsCard">
                <div class="card-body text-center">
                  <img src="../../public/image/sms1.png" class="serviceImage">
                  <h1>SMS</h1>
                  <p >Reach out to your customers in any country with bulk sms </p>
                </div>
              </div>
              <div class="card smsCard">
                <div class="card-body text-center">
                  <img src="../../public/image/voice1.png" class="serviceImage">
                  <h1>PAYMENT</h1>
                  <p>Collect payments instantly when you build your USSD apps. </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <section class="thingsBuild">
        <div class="thingsBuildContent">
            <h1>Things you can build</h1>
        <hr class="mt-2"/>
        <p class="thingsBuildContent_p">Clone one of the following apps, modify and deploy or create a new app</p>
          <div class="shapes">
            <div class="circle6"></div>
          </div>
            <AppTemplates/>
        </div>
      </section>
  </div>
</template>

<script>
    // @ is an alias to /src
    import AppTemplates from '@/components/homeAppTemplates.vue'
    export default {
        components: {
            AppTemplates,
        },
    }
</script>

<style scoped>
* {
    margin: 0;
    padding: 0;
    font-family: 'Roboto', sans-serif;
    font-weight: normal;
}

html {
    scroll-behavior: smooth;
  }
 .scrollBtn{
    width: 40px;
    height: 40px;
    background-color:#3498db;
    border-radius: 50%;
    padding: 10px;
    position: fixed;
    bottom: 20px;
    right: 20px;
    font-size: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    color: #fff;
 }

.scrollBtn.hide {
    display: none;
}
.container-fluid {
    background-color: #f8f8f8;
}


.jumbotron {
  background-color: #2a3342;
  z-index: 1;
  position: relative;
}
.circle1{
  background-color: #8C95AC;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  position: absolute;
  top: 100px;
  left: 240px;
  z-index: 2;
}
.circle2{
  background-color: #536082;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  position: absolute;
  top: 140px;
  left: 540px;
  z-index: 2;
}
.circle3{
  background: #1A2B59;
  width: 160px;
  height: 160px;
  border-radius: 50%;
  position: absolute;
  top: 420px;
  left: 540px;
  z-index: 2;
}
.circle4{
  background-color: #8C95AC;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  position: absolute;
  top: 620px;
  left: 600px;
  z-index: 2;
}
.circle6{
  background: #1A2B59;
  width: 560px;
  height: 560px;
  border-radius: 100%;
  position: absolute;
  top: 890px;
  left: 40px;
  z-index: 2;
  opacity: 0.4;
}

.shapes {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}
.appsContainer{
  position: relative;
  z-index: 2;
}

.jumbotron-content {
    width: 80%;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 100px 0;
  position: relative;
  z-index: 2;
}
.thingsBuildContent{
  z-index: 2;
}

.jumbotron-heading {
    color: #fff;
    font-size: 55px;
    font-weight: bold;
    text-align: left;
    margin-bottom: 20px;
    z-index: 10;
}

.jumbotron p {
    color: #b8b8b8;
    font-size: 16px;
    text-align: left;
    margin-bottom: 30px;
  z-index: 10;
}

.jumbotron .btn-primaryhome {
     padding: 15px;
    border-radius: 10px;
    border: none;
    font-weight: bold;
    background-color: #08dd7c;
    text-align: center;
    text-decoration: none;
    margin-top: 20px;
    color: #29394f;
}

.jumbotron .btn-primaryhome:hover {
    background-color: #888;
    color: #fff;
    transition: background-color 0.2s ease-in-out;
}

.actionLink {
    color: #1a1e23;
    font-size: 16px;
    font-weight: bold;
}

.actionLink:hover {
    color: #888888;
}

.actionBtn {
    margin-top: 20px;
}



.jumbotron-image {
    width: 100%;
    height: 500px;
    border-radius: 20px;
    object-fit: contain;
}

.jumbotron-companyinfo {
    flex: 1;
    flex-basis: 0;
    text-align: left;
    margin-right: 100px;
}

.jumbotron-companyImage {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  flex: 1;
  flex-basis: 0;
}
.bottomService{
  display: flex;
}
.thingsBuild{
  background-color:#2a3342;
  padding-bottom: 50px;
}
.thingsBuild hr{
  color: #ffffff !important;
  background-color: #ffffff !important;
  margin-bottom: 20px;
}
.card{
  background-color: #fff;
  color: #000;
  margin: 10px;
  width: 480px;
  height: 300px;
  border-radius: 0.25rem
}
.card-header{
  display: flex;
  align-items: center;
  padding: 20px;
  border: none;
  background-color: #fff;
}
.card-footer{
  border: none;
  background-color: #fff;
}
.thingsBuild h1{
  font-size: 30px;
  color: #fff;
  font-weight: bold;
}
.thingsBuildContent{
  width: 80%;
  margin: 0 auto;
}
.thingsBuildContent_p{
  color: #fff;
  font-size: 1.2em;
  margin-top: 20px;
  margin-bottom: 20px;
}
.btn-danger{
  background-color: #FF5B4F;
  border-radius: 20px;
  border: none;
}
.btn-success{
  background-color: #09EB84;
  border-radius: 20px;
  border: none;
}

.card-header p{
  margin-left: 10px;
  font-weight: bold;
  font-size: 20px;
  color:#1a1e23;
}
.app_p{
  margin-bottom: 30px;
  padding: 20px;
  color:#1a1e23;
}


.serviceCard{
  background-color: #1A2B59;
  box-shadow: none;
  width: 300px;
  border-radius: 50px;
  text-align: center;
  height: auto;
}

.serviceCard h1{
  color: #F9B250;
  font-size: 22px;
  margin-top: 20px;
  margin-bottom: 20px;
}
.serviceCard p{
  color: #F9B250;
  font-size: 14px;
  text-align: center;
}
.serviceImage{
  height: 100px;
  width: 150px;
  object-fit: contain;
}
.smsCard{
  background-color: #1A2B59;
  box-shadow: none;
  width: 250px;
  border-radius: 50px;
  text-align: center;
  height: auto;
}
.smsCard img{
  height: 80px;
  width: 80px;
  object-fit: contain;
}
.smsCard h1{
  color: #F9B250;
  font-size: 22px;
  margin-top: 20px;
  margin-bottom: 20px;
}
.smsCard p{
  color: #F9B250;
  font-size: 14px;
  text-align: center;
}

.services {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}


@media (max-width: 767px) {
    .topNav{
        display: none;
    }
    .header{
        padding: 10px;
        width: 100%;
        position: fixed;
        background-color: #2a3342;
        z-index: 999;
    }
    .header.shadow {
        box-shadow: 0 2px 1px rgba(0, 0, 0, 0.1);
      }
    .nav {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
    }
    .logo{
        width: 50px;
        height: 50px;
        object-fit: contain;
    }
    .navbar-toggler {
      display: block;
      position: absolute;
      top: 20px;
      right: 20px;
      font-size: 24px;
      color: #000;
      border: none;
      width: 50px;
      background-color: #fff;
      padding: 5px;
      border-radius: 5px;
      cursor: pointer;
      margin-right: 20px;
    }

    .navbar-collapse {
        display: flex;
        flex-direction: row;
        height: 0;
        overflow: hidden;
        transition: height 0.3s ease-in-out;
        width: 100%;
      }

      .navbar-collapse.active {
        height: auto;
        border-top: 1px solid #d2d2d2;
        margin-top: 5px;
        width: 100%;
      }

      .navItem{
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 100%;
      }

    .navLink {
      display: block;
      margin: 10px 0;
    }
    .jumbotron-content{
        width: 90%;
      }

      .jumbotron-heading {
        font-size: 30px;
        text-align: center;
        margin-top: 30px;
      }

      .jumbotron p {
        font-size: 16px;
      }
      .actionBtn{
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .jumbotron-companyinfo {
        margin-right: 0;
      }

      .jumbotron-companyImage {
        display: none;
      }

      .jumbotron-image {
        margin-top: 30px;
        height: 400px;
      }
      /*.appsContainer {*/
      /*  flex-direction: column;*/
      /*  text-align: center;*/
      /*  padding: 10px;*/
      /*}*/
      /*.card{*/
      /*  width: 100%;*/
      /*}*/

      .app_p{
        margin-bottom: 5px;
      }
      .shapes{
        display: none;
      }

  }
</style>
