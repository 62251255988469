<template>
  <Popper>
    <i class="fas fa-info-circle"></i>
    <template #content>
      <div class="card-body">
        <ul class="nav nav-tabs" role="tablist">
          <li class="nav-item">
            <a
              class="nav-link active"
              id="home-tab"
              data-bs-toggle="tab"
              href="#home-1"
              role="tab"
              aria-controls="home-1"
              aria-selected="true"
              >Variables</a
            >
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              id="contact-tab"
              data-bs-toggle="tab"
              href="#contact-1"
              role="tab"
              aria-controls="contact-1"
              aria-selected="false"
              >Snippets</a
            >
          </li>
        </ul>
        <div class="tab-content">
          <div
            class="tab-pane fade active show"
            id="home-1"
            role="tabpanel"
            aria-labelledby="home-tab"
          >
            <div class="table-responsive">
              <table class="table table-condensed">
                <thead>
                  <tr>
                    <th>Description</th>
                    <th>Variable</th>
                    <th>Copy</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>User's current input for this menu</td>
                    <td>
                      <code
                        v-pre
                        style="text-transform: none; font-weight: bolder"
                      >
                        {{ inRequest.input }}
                      </code>
                    </td>
                    <td>
                      <button
                        type="button"
                        class="btn btn-primary btn-rounded btn-icon btn-sm"
                        @click="copyPastVar(`this.inRequest.input`)"
                      >
                        <i class="fas fa-clipboard"></i>
                      </button>
                    </td>
                  </tr>
                  <tr>
                    <td>Mobile number of dialer</td>
                    <td>
                      <code
                        v-pre
                        style="text-transform: none; font-weight: bolder"
                      >
                        {{ session.mobile }}
                      </code>
                    </td>
                    <td>
                      <button
                        type="button"
                        class="btn btn-primary btn-rounded btn-icon btn-sm"
                        @click="copyPastVar(`session.mobile`)"
                      >
                        <i class="fas fa-clipboard"></i>
                      </button>
                    </td>
                  </tr>
                  <tr>
                    <td>Network of dialer</td>
                    <td>
                      <code
                        v-pre
                        style="text-transform: none; font-weight: bolder"
                      >
                        {{ session.network }}
                      </code>
                    </td>
                    <td>
                      <button
                        type="button"
                        class="btn btn-primary btn-rounded btn-icon btn-sm"
                        @click="copyPastVar(`session.network`)"
                      >
                        <i class="fas fa-clipboard"></i>
                      </button>
                    </td>
                  </tr>
                  <tr>
                    <td>Session ID</td>
                    <td>
                      <code
                        v-pre
                        style="text-transform: none; font-weight: bolder"
                      >
                        {{ session.sessionId }}
                      </code>
                    </td>
                    <td>
                      <button
                        type="button"
                        class="btn btn-primary btn-rounded btn-icon btn-sm"
                        @click="copyPastVar(`session.sessionId`)"
                      >
                        <i class="fas fa-clipboard"></i>
                      </button>
                    </td>
                  </tr>

                  <tr
                    v-for="(menu, index) in menusList"
                    v-bind:value="menu.uniqueId"
                    :key="index"
                  >
                    <td>{{ menu.displayText }}</td>
                    <td>
                      <code
                        style="text-transform: lowercase; font-weight: bolder"
                      >
                        <span v-pre>{{</span>session.inputs.{{
                          menu.userDefinedName
                        }}<span v-pre>}}</span>
                      </code>
                    </td>
                    <td>
                      <button
                        type="button"
                        @click="
                          copyPastVar(`session.inputs.${menu.userDefinedName}`)
                        "
                        class="btn btn-primary btn-rounded btn-icon btn-sm"
                      >
                        <i class="fas fa-clipboard"></i>
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div
            class="tab-pane fade"
            id="contact-1"
            role="tabpanel"
            aria-labelledby="contact-tab"
          >
            <div
              class="card card-inverse-info"
              v-for="(snippet, index) in snippets"
              :key="index"
            >
              <div class="card-body">
                <div class="card-title">{{ snippet.description }}</div>
                <highlightjs
                  language="javascript"
                  :code="snippet.codeSnippet"
                />
                <br />
                <button
                  class="btn btn-warning btn-sm text-white float-right"
                  @click="copyToClipboard(snippet.codeSnippet)"
                >
                  <i class="fa fa-copy"></i>
                  Copy to clipboard
                </button>
              </div>
            </div>
            <br />
          </div>
        </div>
      </div>
    </template>
  </Popper>
</template>

<script>
import { defineComponent } from "vue";
import { useToast } from "vue-toastification";
import Popper from "vue3-popper";
import jsonSnippetsForPopover from "./jsonSnippetsForPopover";
export default defineComponent({
  components: {
    Popper,
  },
  setup() {
    const toast = useToast();
    return { toast };
  },
  mixins: [jsonSnippetsForPopover],
  data() {
    return {
      menuItems: [],
    };
  },
  computed: {
    menusList() {
      return this.$store.getters.getMenusLite;
    },
  },
  methods: {
    copyPastVar(pasteData) {
      return this.copyToClipboard(pasteData); //todo remove this and implement paste in future
    },
    copyToClipboard(codeSnippet) {
      const text = codeSnippet;
      navigator.clipboard.writeText(text);
      this.toast.success("Copied");
    },
  },
});
</script>
<style scoped>
:deep(.popper) {
  background: #0b1541;
  padding: 10px;
  border-radius: 20px;
  color: #fff;
  font-size: 0.8em;
}

:deep(.popper #arrow::before) {
  background: #0b1541;
}

:deep(.popper:hover),
:deep(.popper:hover > #arrow::before) {
  background: #121f58;
}
.table td,
.table th {
  padding: 5px;
}
.btn.btn-icon {
  width: 35px;
  height: 35px;
  padding: 0;
}
.card-body {
  text-transform: none;
}
pre {
  background: color(gray-lighter);
  padding: 0;
  font-size: 13px;
  margin-bottom: 0rem;
}
</style>
