<template>
  <Popper>
    <i class="fas fa-info-circle"></i>
    <template #content>
      <div class="card-body">
        <h4 class="h4">Session Variables</h4>

        <div class="table-responsive">
          <table class="table table-condensed">
            <thead>
              <tr>
                <th>Description</th>
                <th>Variable</th>
                <th>Paste</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Mobile number of dialer</td>
                <td>
                  <code
                    v-pre
                    style="text-transform: lowercase; font-weight: bolder"
                  >
                    {{ session.mobile }}
                  </code>
                </td>
                <td>
                  <button
                    type="button"
                    class="btn btn-primary btn-rounded btn-icon btn-sm"
                    @click="copyPastVar(`session.mobile`)"
                  >
                    <i class="fas fa-clipboard"></i>
                  </button>
                </td>
              </tr>
              <tr>
                <td>Network of dialer</td>
                <td>
                  <code
                    v-pre
                    style="text-transform: lowercase; font-weight: bolder"
                  >
                    {{ session.network }}
                  </code>
                </td>
                <td>
                  <button
                    type="button"
                    class="btn btn-primary btn-rounded btn-icon btn-sm"
                    @click="copyPastVar(`session.network`)"
                  >
                    <i class="fas fa-clipboard"></i>
                  </button>
                </td>
              </tr>
              <tr>
                <td>Session ID</td>
                <td>
                  <code
                    v-pre
                    style="text-transform: lowercase; font-weight: bolder"
                  >
                    {{ session.sessionId }}
                  </code>
                </td>
                <td>
                  <button
                    type="button"
                    class="btn btn-primary btn-rounded btn-icon btn-sm"
                    @click="copyPastVar(`session.sessionId`)"
                  >
                    <i class="fas fa-clipboard"></i>
                  </button>
                </td>
              </tr>

              <tr
                v-for="(menu, index) in menusList"
                v-bind:value="menu.uniqueId"
                :key="index"
              >
                <td>{{ menu.displayText }}</td>
                <td>
                  <code style="text-transform: lowercase; font-weight: bolder">
                    <span v-pre>{{</span>session.inputs.{{ menu.userDefinedName
                    }}<span v-pre>}}</span>
                  </code>
                </td>
                <td>
                  <button
                    type="button"
                    @click="
                      copyPastVar(`session.inputs.${menu.userDefinedName}`)
                    "
                    class="btn btn-primary btn-rounded btn-icon btn-sm"
                  >
                    <i class="fas fa-clipboard"></i>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </template>
  </Popper>
</template>

<script>
import { defineComponent } from "vue";
import Popper from "vue3-popper";

export default defineComponent({
  components: {
    Popper,
  },
  data() {
    return {
      menuItems: [],
    };
  },
  computed: {
    menusList() {
      return this.$store.getters.getMenusLite;
    },
  },
  methods: {
    copyPastVar(pasteData) {
      let eventData = {
        pasteText: pasteData,
      };
      this.$parent.pasteText(eventData);
    },
  },
});
</script>
<style scoped>
:deep(.popper) {
  background: #0b1541;
  padding: 10px;
  border-radius: 20px;
  color: #fff;
  font-size: 0.8em;
}

:deep(.popper #arrow::before) {
  background: #0b1541;
}

:deep(.popper:hover),
:deep(.popper:hover > #arrow::before) {
  background: #121f58;
}
.table td,
.table th {
  padding: 5px;
}
.btn.btn-icon {
  width: 35px;
  height: 35px;
  padding: 0;
}
</style>
