import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import Toast from "vue-toastification";
import Vue3Tour from 'vue3-tour'
import 'vue3-tour/dist/vue3-tour.css'

import "vue-toastification/dist/index.css";
import store from './store'

import hljs from 'highlight.js/lib/core';
import javascript from 'highlight.js/lib/languages/javascript';
import hljsVuePlugin from "@highlightjs/vue-plugin";
hljs.registerLanguage('javascript', javascript);
import "highlight.js/styles/atom-one-dark.css";



const options = {
    // You can set your default options here
};
createApp(App).use(store)
    .use(Toast, options)
    .use(Vue3Tour)
    .use(hljsVuePlugin)
    .use(router)
    .mount('#app')
