<template>
  <div>
    <div class="row">
      <div class="col-md-12 grid-margin">
        <div class="row">
          <div class="col-8 mb-0 mb-xl-0">
            <h4 class="font-weight-bold">My Shortcode Extensions</h4>
          </div>
          <div class="col-4">
            <router-link class="btn btn-primary btn-icon-text text-white float-right" to="/buy-shortcode">
              <span class="h4 text-white">Buy an Extension </span>
            </router-link>
          </div>
        </div>
      </div>
    </div>
    <div class="form-group" v-if="tableData.length==0">
      <div class="card card-inverse-info">
        <div class="card-body">
          <div class="card-title font-weight-bold">
            <div class="badge badge-pill badge-outline-info">
              <i class="fas fa-info-circle"></i>
            </div>
            You have not purchased any ShortCode Extensions</div>
          <div class="card-text">
          </div>
        </div>
      </div>
    </div>
    <div class="table-responsive" v-if="tableData.length">
      <table id="order-listing" class="table">
        <thead>
          <tr>

            <th>Shortcode</th>
            <th>Country</th>
            <th>Start Date</th>
            <th>Expiry Date</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="data in tableData" :key="data.appId">
            <td>{{ data.shortCode }}</td>
            <td>{{ data.country }}</td>
            <td>{{ data.startDate }}</td>
            <td>{{ data.expiryDate }}</td>
            <td>
              <router-link class="btn btn-outline-success ml-2 design" :to="'/connect-shortcode/' + data.uuid"
                data-toggle="tooltip" title="Connect App" >
                <i class="fa fa-link"></i> Connect App
              </router-link>
              <button type="button" class="btn btn-outline-primary ml-2 appsettings" @click="openPaymentModal(data)"
                data-toggle="tooltip" title="Renew ShortCode">
                <i class="fa fa-credit-card"></i> Make Payment
              </button>
            </td>
          </tr>
        </tbody>
      </table>


    </div>
    <div>
      <div class="modal fade" id="makePaymentModal" tabindex="-1" role="dialog" aria-labelledby="ModalLabel"
           aria-hidden="true">
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="ModalLabel">
                Make Payment
              </h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <form>
                <div class="form-group row">
                  <label class="col-5">Short Code :</label>
                  <span class="col-7" >
                    {{purchase.shortCode}}
                  </span>
                </div>
                <div class="form-group row">
                  <label class="col-5 ">Country :</label>
                  <span class="col-7" >
                    {{purchase.country}}
                  </span>
                </div>
                <div class="form-group row">
                  <label class="col-5">Duration of purchase :</label>
                  <span class="col-7" >
                   <select class="form-control"  style="
                    background-color: #2b2e4c;
                    color: #e9e6e6;
                    border: 1px solid #424351;
                  " v-model="purchase.period">
                  <option value="">..Select Period..</option>
                  <option value="1">1 Month</option>
                  <option value="2">2 Months</option>
                  <option value="3">3 Months</option>
                  <option value="4">4 Months</option>
                  <option value="5">5 Months</option>
                  <option value="6">6 Months</option>
                  <option value="7">7 Months</option>
                  <option value="8">8 Months</option>
                  <option value="9">9 Months</option>
                  <option value="10">10 Months</option>
                  <option value="11">11 Months</option>
                  <option value="12">12 Months</option>
                </select>
                  </span>
                </div>
              </form>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-success text-white" @click="proceedToPayment()">
                Proceed To Payment
              </button>
              <button type="button" class="btn btn-light" @click="closeModal()">
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="modal fade" id="prodgressDialog" tabindex="-1" role="dialog" aria-labelledby="ModalLabel"
           aria-hidden="true">
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="loader-demo-box1">
              <div class="dot-opacity-loader">
                <span></span>
                <span></span>
                <span></span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import api from "@/shared/menuApi";
import { useToast } from "vue-toastification";

function openInNewTab(url) {
   window.open(url, '_blank', 'noreferrer');
}

export default {
  name: "myShortCodeExtensions",
  components: {

  },
  setup() {
    const toast = useToast();
    return { toast };
  },
  data() {
    return {
      purchase: {},
      tableData: [],
      errors: {},
    };
  },
  methods: {
    getMyShortCodes() {
      let vm = this;
      api.doGet(`/api/myPurchasedShortCodes`)
              .then((response) => {
                console.log('MyShortcodes response>>',response);
                vm.tableData=response;
              })
              .catch((error) => {
                this.toast.error(error.message);
              })
    },
    openPaymentModal(data){
      console.log('The data is>>',data)
      this.purchase.id=data.id;
      this.purchase.shortCode=data.shortCode;
      this.purchase.country=data.country;
      this.purchase.period = "1";
      jQuery("#makePaymentModal").modal("show");
    },
    closeModal(){
      jQuery("#makePaymentModal").modal("hide");
    },
    proceedToPayment(){
      jQuery("#makePaymentModal").modal("hide");
      let payload = {
        id:this.purchase.id,
        leaseCount : this.purchase.period,
        extensionUuid:this.purchase.extensionUuid,
        shortCode : this.purchase.shortCode
      }
      jQuery("#prodgressDialog").modal("show");
      api.doPost("/api/subscription/renewShortcode", payload)
              .then((response) => {
                console.log('Response from server >>', response);
                if (response.status === "01") {
                  setTimeout(function () {
                    jQuery("#prodgressDialog").modal("hide");
                  }, 3000);
                  openInNewTab(response.redirectUrl);
                } else {
                  jQuery("#prodgressDialog").modal("hide");
                  this.toast.info(response.message)
                }
              })
              .catch((error) => {
                this.toast.error(error.message);
              });
    },
  },
  mounted() {
    this.getMyShortCodes()
  }

};
</script>
<style scoped>
#submitBtn {
  margin-top: 20px;
}
</style>
