import { createStore } from 'vuex'

export default createStore({
  state: {
    menusLite: [],
    appData: {},
    currentEditingMenu: {},
  },
  mutations: {
    updateMenusLite(state, menus) {
      state.menusLite = menus;
    },
    setCurrentApp(state, appData) {
      state.appData = appData;
    },
    setDialingPhone(state, mobileNumber) {
      state.mobileNumber = mobileNumber;
    },
    setCurrentEditingMenu(state, menu) {
      state.currentEditingMenu = menu;
    },
    setCurrentSessionId(state, sessionId) {
      state.sessionId = sessionId;
    }
  },
  getters: {
    getMenusLite(state) {
      return state.menusLite
    },
    getCurrentApp(state) {
      return state.appData;
    },
    getDialingPhone(state) {
      return state.mobileNumber;
    },
    getCurrentEditingMenu(state) {
      return state.currentEditingMenu;
    },
    getCurrentSessionId(state) {
      return state.sessionId;
    }
  },
  actions: {
  },
  modules: {
  }
})
