<template>
  <div>
    <DialPage :homeUrl="previousUrl" @numberDialed="numberDialed" />
    <MenuDisplay ref="menuDisplayModal" />
  </div>
</template>

<script>
import DialPage from "@/components/emulatorDialComponent.vue";
import MenuDisplay from "@/components/emulatorModalComponent.vue";
export default {
  name: "StarterPage",
  data() {
    return {
      selectedHero: undefined,
      message: "",
      capeMessage: "",
      previousUrl: ""
    };
  },
  components: {
    DialPage,
    MenuDisplay,
  },
  methods: {
    numberDialed(data) {
      var child = this.$refs.menuDisplayModal;
      child.showModal(data);
    }
  },
  mounted() {
    this.previousUrl = this.$route.query.returnUrl;
    if (this.previousUrl == null) {
      this.previousUrl = "";
    }
  },
};
</script>
