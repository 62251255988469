<template>
      <div class="row">
                        <div class="col-md-12">
                        <div class="card">
                            <div class="card-body">
                            <h4 class="card-title h4">SEND SMS</h4>
                                <div class="row" >
                                <div class="col-md-6">
                                    <div class="form-group row">
                                    <label class="col-sm-4 col-form-label">Sender Name</label>
                                    <div class="col-sm-8">
                                        <input
                                        type="text"
                                        class="form-control"
                                        v-model="sendSms.smsSender"
                                        placeholder="Sender Name"
                                        />
                                    </div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group row">
                                    <label class="col-sm-5 col-form-label">Receipient Mobile</label>
                                    <div class="col-sm-7">
                                        <input
                                        type="text"
                                        class="form-control"
                                        v-model="sendSms.mobile"
                                        placeholder="Mobile Number"
                                        />
                                    </div>
                                    </div>
                                </div>
                                </div>

                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="form-group">
                                             <label class="h4">Sms Message</label>
                                        <textarea class="form-control" rows="5" v-model="sendSms.smsMessage"></textarea>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        </div>
                    </div>
</template>

<script>
 export default {
    props: {
      sendSms:{
          type:Object,
          default:{}
      }
  },
  methods: {

  },
}
</script>

<style lang="scss" scoped>

</style>