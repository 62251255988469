
//# "editor.formatOnSave": false
export default {
  data() {
    return {
      snipInits: [
        {
          description: "Payload of all inputs entered by user",
          codeSnippet: "",
          keyValues: ["inputs"],
        },
        {
          description:
            "Payload of all inputs, user's mobile number and network",
          codeSnippet: "",
          keyValues: ["inputs", "sessiondata"],
        },
        {
          description: "Payload of current user's mobile number and network",
          codeSnippet: "",
          keyValues: ["sessiondata"],
        },
        {
          description: "Payload of all inputs and their text",
          codeSnippet: "",
          keyValues: ["inputAndLabels"],
        },
      ],
    };
  },
  computed: {
    snippets() {
      const retData = [];
      for (let k = 0, len = this.snipInits.length; k < len; k++) {
        let snippet = this.snipInits[k];
        snippet.codeSnippet = this.getCodeSnippet(snippet);
        retData.push(snippet);
      }
      return retData;
    },
  },
  methods: {
    buildMenusKeyValues() {
      const menusKv = [];
      this.menusList.forEach((m) => {
        menusKv.push(
          `"${m.userDefinedName}":"{{session.inputs.${m.userDefinedName}}}"`
        );
      });
      return menusKv.join(",\n");
    },
    buildMenusInputAndLabelsKeyValues() {
      const menusKv = [];
      this.menusList.forEach((m) => {
        menusKv.push(
          `"${m.userDefinedName}":"{{session.inputs.${m.userDefinedName}}}"`
        );
        menusKv.push(
          `"${m.userDefinedName}Label":"{{session.inputs.${m.userDefinedName}Label}}"`
        );
      });
      return menusKv.join(",\n");
    },
    buildMobileAndNetworkKeyValues() {
      const menusKv = [];
      menusKv.push(`"network":"{{session.network}}"`);
      menusKv.push(`"mobileNumber":"{{session.mobile}}"`);
      return menusKv.join(",\n");
    },
    getCodeSnippet(snippet) {
      let codeSnippet = "{\n";
      const vm = this;
      snippet.keyValues.forEach(function (item,index) {
        if (item == "inputs") {
          codeSnippet += vm.buildMenusKeyValues();
        } else if (item == "sessiondata") {
          codeSnippet += vm.buildMobileAndNetworkKeyValues();
        } else if (item == "inputAndLabels") {
          codeSnippet += vm.buildMenusInputAndLabelsKeyValues();
        }
        if(index===(snippet.keyValues.length-1)){
          codeSnippet += "\n";
        }else{
          codeSnippet += ",\n";
        }
      });
      codeSnippet += "}";
      return codeSnippet;
    }

  },
};

